<template>
  <confirm-dialog
    v-model="show"
    :title="$t('PayoutMethodsConfirmCancelDialog.title')"
    :confirm-button-text="$t('PayoutMethodsConfirmCancelDialog.cancel')"
    :cancel-button-text="$t('PayoutMethodsConfirmCancelDialog.close')"
    @input:confirm="cancel"
  >
    <i18n-t :keypath="content" tag="span">
      <span class="font-weight-bold">
        {{ $t("PayoutMethodsConfirmCancelDialog.close") }}
      </span>

      <span class="font-weight-bold">
        {{ $t("PayoutMethodsConfirmCancelDialog.cancel") }}
      </span>
    </i18n-t>
  </confirm-dialog>
</template>

<script>
import { mdiAlert } from "@mdi/js";
import ConfirmDialog from "@/components/ConfirmDialog";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { useDynamicContent } from "@/composables/dynamic-content";

export default {
  name: "PayoutMethodsConfirmCancelDialog",

  components: { ConfirmDialog },

  props: {
    value: { type: Boolean, default: false },
    editing: { type: Boolean, default: false },
  },

  emits: ["input"],

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const payoutMethodStore = usePayoutMethodStore();
    const { setContent } = useDynamicContent();

    return {
      dialogPanelStore,
      payoutMethodStore,
      setContent,
    };
  },

  data() {
    return {
      mdiAlert,
      content: this.editing
        ? "PayoutMethodsConfirmCancelDialog.editContent"
        : "PayoutMethodsConfirmCancelDialog.content",
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    cancel() {
      if (this.editing) {
        this.dialogPanelStore.hide();
        this.payoutMethodStore.selected = null;
        return;
      }

      this.setContent(NewPayoutMethods);
    },
  },
};
</script>
