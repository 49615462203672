import { i18n } from "@/plugins/i18n";
import { isPast, parse } from "date-fns";

export const payoutMethodDisplayNames = {
  ACH: i18n.global.t("payoutMethods.ach"),
  CHECKING: i18n.global.t("payoutMethods.checking"),
  SAVINGS: i18n.global.t("payoutMethods.savings"),
  DEBIT: i18n.global.t("payoutMethods.debit"),
  PREPAID: i18n.global.t("payoutMethods.prepaid"),
  VENMO: i18n.global.t("payoutMethods.venmo"),
  PAYPAL: i18n.global.t("payoutMethods.paypal"),
  ECHECK: i18n.global.t("payoutMethods.eCheck"),
  COINBASE: i18n.global.t("payoutMethods.coinbase"),
  RTP: i18n.global.t("payoutMethods.rtp"),
};

export function payoutMethodDetail(payoutMethod) {
  const source = payoutMethod.source;

  if (source === "ACH" || source === "RTP") {
    return payoutMethod.ach;
  }

  if (source === "DEBIT") {
    return payoutMethod.card;
  }

  if (source === "PAYPAL") {
    return payoutMethod.paypal;
  }

  if (source === "VENMO") {
    return payoutMethod.venmo;
  }

  if (source === "COINBASE") {
    return payoutMethod.coinbase;
  }

  if (source === "PREPAID") {
    return payoutMethod.prepaid;
  }
}

export function findDefaultPayoutMethod(methods) {
  if (!methods || !methods.length) {
    return null;
  }

  return methods.find((m) => payoutMethodDetail(m).default);
}

export function expiringSoon(date) {
  if (!date) return false;

  const now = new Date();
  const expiryDate = parse(`${date.month}/${date.year}`, "MM/yy", now);
  return isPast(expiryDate);
}

export function getDisplayName(pm) {
  if (pm.source === "ACH") {
    const type = payoutMethodDisplayNames[pm.ach.type];
    return pm.ach.classification
      ? `${pm.ach.classification.toLowerCase()} ${type}`
      : type;
  }

  return payoutMethodDisplayNames[pm.source];
}

export function getAccountNumber(pm) {
  switch (pm.source) {
    case "ACH":
      return pm.ach.accountNumber;
    case "RTP":
      return pm.ach.accountNumber;
    case "VENMO":
      return pm.venmo.phone.value;
    case "PAYPAL":
      return pm.paypal[Object.keys(pm.paypal)[0]].value;
    case "COINBASE":
      return pm.coinbase.accountNumber;
    case "PREPAID":
      if (pm.prepaid.accountNumber) {
        return pm.prepaid.accountNumber;
      } else {
        return pm.prepaid.cardNumber;
      }
    default:
      return pm.card.cardNumber ?? pm.card.alias;
  }
}
