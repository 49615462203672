import get from "lodash/get";
import head from "lodash/head";
import { i18n } from "@/plugins/i18n";

export function resolveErrorMsg(err, context) {
  const ctx = `errorCodes.${context}`;
  const code = get(err, "response.data.code");
  if (code) {
    const fieldErrors = get(err, "response.data.developerInfo.fieldError", []);

    const nums = /\d+/;
    const retryCount = /RetryCount(\s|$):(\s|$)\d/;

    let subCode = head(
      fieldErrors
        .filter((fe) => retryCount.test(fe.message))
        .map((fe) => fe.message.match(retryCount))
    );

    if (retryCount.test(subCode)) {
      subCode = String(subCode).match(nums)[0];
    }

    if (!subCode) {
      subCode = head(
        fieldErrors
          .filter((fe) => nums.test(fe.message))
          .map((fe) => fe.message.match(nums))
      );
    }

    // subcode specific msg
    if (subCode && i18n.global.te(`${ctx}.${code}.${subCode}`)) {
      return i18n.global.t(`${ctx}.${code}.${subCode}`);
    }

    // code default
    if (i18n.global.te(`${ctx}.${code}.default`)) {
      return i18n.global.t(`${ctx}.${code}.default`);
    }

    // code specific
    if (
      i18n.global.te(`${ctx}.${code}`) &&
      typeof i18n.global.t(`${ctx}.${code}`) === "string"
    ) {
      return i18n.global.t(`${ctx}.${code}`);
    }
  }

  // context default
  if (i18n.global.te(`${ctx}.default`)) {
    return i18n.global.t(`${ctx}.default`);
  }

  // just the context
  if (i18n.global.te(ctx)) {
    return i18n.global.t(ctx);
  }

  // the global default
  return i18n.global.t("errorCodes.genericErrors.default");
}
