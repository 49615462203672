function getFlattedRoutes(routes) {
  return flatten([], routes);
}

function flatten(flattened, routes, parent) {
  for (const route of routes) {
    let r = { ...route };

    // if it is a child
    if (parent) {
      r = {
        ...route,
        path: `${parent.path}/${route.path}`,
        meta: { ...route.meta, ...parent.meta },
      };

      if (!r.meta.layout) {
        r.meta.layout = parent.component;
      }

      if (!r.beforeEnter && parent.beforeEnter) {
        r.beforeEnter = parent.beforeEnter;
      }
    }

    // if it has children
    if (r.children) {
      flatten(flattened, r.children, r);
    } else {
      flattened.push(r);
    }
  }

  return flattened;
}

export default getFlattedRoutes;
