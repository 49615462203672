import client from "@/api/clients/ddp-client";

export default {
  createOtp(data) {
    return client.post("/v1/otp", data);
  },

  getRecipient(recipientId) {
    return client.get(`/v2/recipients/${recipientId}`);
  },

  getRecipientPreferences(recipientId) {
    return client.get(`/v2/recipients/${recipientId}/preferences`);
  },

  signUp(data) {
    return client.post(`/v2/recipients`, data);
  },

  getTransactionSummary(recipientId) {
    return client.get(`/v2/recipients/${recipientId}/transactions/summary`);
  },

  getPayoutMethods(recipientId) {
    return client.get(`/v2/recipients/${recipientId}/accounts`);
  },
  getMechantConfigration(merchantId) {
    return client.get(
      `/v2/configurations/merchants/${merchantId}/portalConfiguration`
    );
  },

  getAllowedPayoutMethods(recipientId, transactionId) {
    return client.get(`/v2/recipients/${recipientId}/methods`, {
      params: {
        filter: "allowed",
        "transaction-ids": transactionId,
      },
    });
  },

  deletePayoutMethod(recipientId, accountId) {
    return client.delete(`/v2/recipients/${recipientId}/accounts/${accountId}`);
  },

  updatePayoutMethod(recipientId, accountId, data) {
    return client.patch(
      `/v2/recipients/${recipientId}/accounts/${accountId}`,
      data
    );
  },

  getTransactionHistory(
    recipientId,
    pageNumber,
    pageSize,
    fromDate,
    toDate,
    merchant,
    filterStatus
  ) {
    return client.get(`/v2/recipients/${recipientId}/transactions`, {
      params: {
        filter: filterStatus,
        "merchant-group": merchant,
        "from-date": fromDate,
        "to-date": toDate,
        "page-no": pageNumber,
        "page-size": pageSize,
      },
    });
  },

  getLast5Payouts(recipientId) {
    return client.get(`/v2/recipients/${recipientId}/transactions`, {
      params: { filter: "last5" },
    });
  },

  getAvailablePayouts(recipientId, pageSize) {
    return client.get(`/v2/recipients/${recipientId}/transactions`, {
      params: { filter: "pending", "page-size": pageSize },
    });
  },

  createPayoutMethod(recipientId, payoutMethod) {
    return client.post(`/v2/recipients/${recipientId}/accounts`, payoutMethod);
  },

  signOut() {
    return client.delete("/v1/users/sign-out");
  },

  rejectPayment(recipientId, transactionId, paymentId, requestBody) {
    return client.post(
      `/v2/recipients/${recipientId}/transactions/${transactionId}/payments/${paymentId}/actions?action=${requestBody.action}`,
      requestBody
    );
  },

  updatePreferences(recipientId, preferences) {
    return client.patch(
      `/v2/recipients/${recipientId}/preferences`,
      preferences
    );
  },

  getPreferences(recipientId) {
    return client.get(`/v2/recipients/${recipientId}/preferences`);
  },

  getPayout(recipientId, transactionId) {
    return client.get(
      `/v2/recipients/${recipientId}/transactions/${transactionId}`
    );
  },

  disbursePayout(recipientId, transactionId, paymentId, accountDetails) {
    return client.post(
      `/v2/recipients/${recipientId}/transactions/${transactionId}/payments/${paymentId}/actions?action=${accountDetails.action}`,
      accountDetails
    );
  },

  updateProfile(recipientId, recipientData) {
    return client.patch(`/v2/recipients/${recipientId}`, recipientData);
  },

  updateRecipientPreferences(recipientId, recipientPreferencesData) {
    return client.patch(
      `/v2/recipients/${recipientId}/preferences`,
      recipientPreferencesData
    );
  },

  getTransactionMerchant(recipientId) {
    return client.get(`/v2/recipients/${recipientId}/merchants`);
  },

  approvePayout(recipientId, transactionId, paymentId) {
    return client.post(
      `/v2/recipients/${recipientId}/transactions/${transactionId}/payments/${paymentId}/actions?action=approve`,
      { action: "approve" }
    );
  },

  getRoutingNumber() {
    return client.get(`/v1/payments/routingInfo`, {
      params: { paymentType: "COINBASE" },
    });
  },

  getFinancialInstitutionDetails(routingNumber) {
    return client.post(`/v2/banks/searches`, { routingNumber: routingNumber });
  },

  createMoneyNetworkSession(recipientId, brandObj) {
    return client.post(`v1/recipients/${recipientId}/cards/session`, brandObj);
  },

  enrollMoneyNetwork(recipientId, form) {
    return client.post(`/v1/recipients/${recipientId}/card`, form);
  },

  linkPayment(paymentId, data) {
    return client.patch(`/v1/payments/${paymentId}`, data);
  },

  getNotificationData() {
    return fetch(
      process.env.VUE_APP_DDP_ASSETS_URL + "/docs/portal_notifications_en.json",
      { cache: "no-cache" }
    ).then((data) => data.json());
  },
};
