<script>
import NameTextField from "@/components/forms/NameTextField";
import AppTextField from "@/components/forms/AppTextField";
import {
  email,
  inputPattern,
  linkClicked,
  minLength,
  required,
} from "@/utils/rules";
import SecureTextField from "@/components/forms/SecureTextField";
import DateTextField from "@/components/forms/DateTextField";
import PhoneNumberField from "@/components/forms/PhoneNumberField";
import { computed, onMounted, reactive, ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { usePayoutStore } from "@/stores/payout";
import StreetAddressForm from "@/components/forms/StreetAddressForm";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import { format, isValid, parse } from "date-fns";
import { i18n } from "@/plugins/i18n";
import PasswordField from "@/components/sign-in/PasswordField";
import { encrypt } from "@/utils/encryption";
import { cloneDeep } from "lodash/lang";
import MoneyNetworkLogo from "@/components/payout-methods/money-network/MoneyNetworkLogo";
import { useMoneyNetworkEnrollment } from "@/composables/money-network-enrollment-method";
import { useDisplay } from "vuetify";

export default {
  name: "NewMoneyNetworkForm",

  components: {
    PasswordField,
    PayoutMethodsConfirmCancelDialog,
    StreetAddressForm,
    PhoneNumberField,
    DateTextField,
    SecureTextField,
    AppTextField,
    NameTextField,
    MoneyNetworkLogo,
  },

  props: {
    enrollmentData: { type: Object, required: true },
  },

  setup(props) {
    const form = ref(null);
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const { xs } = useDisplay();
    const user = computed(() => {
      return authStore.user;
    });
    const getPayoutSrc = computed(() => {
      return payoutStore.getPayoutTypeSelectedSource;
    });

    const isStreetAddressDisabled = computed(() => {
      return payoutStore.getPayoutTypeSelectedSource !== "US_DEBIT";
    });

    const moneynetworkPolicyURL = computed(() => {
      return "https://disclosuregenerator.mynycb.com/api/disclosuredocument/Privacy.pdf";
    });

    // Person Profile
    function usePersonProfile() {
      const personProfile = reactive({
        firstName: "",
        lastName: "",
        address: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          zipcode: null,
        },
        shippingAddress: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: "",
        },
        dob: "",
        ssn: "",
        primaryPhone: "",
        mobilePhone: "",
      });

      function dobDateRange(v) {
        const now = new Date();

        const DOB = parse(v, String(i18n.global.t("dateFormat.short")), now);
        const oldestDobAllowed = now.getFullYear() - 120;
        const youngestDobAllowed = now.getFullYear() - 18;

        return (
          (isValid(DOB) &&
            DOB.getFullYear() >= oldestDobAllowed &&
            DOB.getFullYear() <= youngestDobAllowed) ||
          i18n.global.t("NewMoneyNetworkForm.validDOB")
        );
      }

      const showShippingAddress = ref(true);
      const hideMobile = ref(false);

      function prePopulatePersonProfile() {
        showShippingAddress.value = false; // jiggle the handle for VCheckbox

        personProfile.firstName = user.value.firstName;
        personProfile.lastName = user.value.lastName;
        personProfile.address.address1 = user.value.address?.street;
        personProfile.address.city = user.value.address?.city;
        personProfile.address.state = user.value.address?.stateOrProvince;
        personProfile.address.zipcode = user.value.address?.postalCode;
        personProfile.email = user.value.emailAddress.value;
      }

      function personProfileAPISchema() {
        const dob = parse(
          personProfile.dob,
          String(i18n.global.t("dateFormat.short")),
          new Date()
        );
        const formattedDOB = format(dob, "yyyyMMdd");
        const formattedSSN = personProfile.ssn.replaceAll("-", "");

        if (!showShippingAddress.value) {
          personProfile.shippingAddress = cloneDeep(personProfile.address);
        }
        return {
          firstName: personProfile.firstName,
          lastName: personProfile.lastName,
          middleName: "",
          dob: encrypt(formattedDOB),
          ssn: formattedSSN && encrypt(formattedSSN),
          homePhone: hideMobile.value
            ? personProfile.primaryPhone
            : personProfile.mobilePhone,
          workPhone: personProfile.primaryPhone,
          primaryAddress: {
            street: personProfile.address.address1,
            city: personProfile.address.city,
            stateOrProvince: personProfile.address.state,
            postalCode: personProfile.address.zipcode,
            country:
              user.value.address?.country === undefined
                ? "USA"
                : user.value.address?.country,
          },
          shippingAddress: {
            street: personProfile.shippingAddress.address1,
            city: personProfile.shippingAddress.city,
            stateOrProvince: personProfile.shippingAddress.state,
            postalCode: personProfile.shippingAddress.zipcode,
            country:
              user.value.address?.country === undefined
                ? "USA"
                : user.value.address?.country,
          },
        };
      }

      onMounted(() => prePopulatePersonProfile());

      return {
        personProfile,
        showShippingAddress,
        hideMobile,
        dobDateRange,
        personProfileAPISchema,
      };
    }

    // User Profile
    function useUserProfile() {
      const userProfile = reactive({
        email: "",
        userName: "",
        pw: "",
        confirmPw: "",
      });

      function onlyAlphaNumeric(v) {
        return (
          !/[^A-Za-z\d]/g.test(v) ||
          i18n.global.t("NewMoneyNetworkForm.validUserName")
        );
      }

      const passwordsMatch = computed(() => {
        return (
          userProfile.pw === userProfile.confirmPw ||
          i18n.global.t("NewMoneyNetworkForm.pwsDoNotMatch")
        );
      });

      function userProfileAPISchema() {
        return {
          userName: encrypt(userProfile.userName),
          password: encrypt(userProfile.pw),
          emailAddress: {
            value: userProfile.email,
            type: "work",
            primary: true,
          },
        };
      }

      onMounted(() => {
        userProfile.email = user.value.emailAddress.value;
      });

      return {
        userProfile,
        onlyAlphaNumeric,
        passwordsMatch,
        userProfileAPISchema,
      };
    }

    // Security Questions
    function useSecurityQuestions() {
      const securityQuestions =
        props.enrollmentData.sessionContext.securityQuestionAnswers.map(
          (question) => ({ text: question.value, value: question.key })
        );
      const securityQuestionOne = reactive({
        options: cloneDeep(securityQuestions),
        selected: null,
        answer: null,
      });
      const securityQuestionTwo = reactive({
        options: cloneDeep(securityQuestions),
        selected: null,
        answer: null,
      });

      function removeQuestionFromListOne() {
        securityQuestionOne.options = securityQuestions.filter(
          (opt) => opt.value !== securityQuestionTwo.selected
        );
      }
      //
      function removeQuestionFromListTwo() {
        securityQuestionTwo.options = securityQuestions.filter(
          (opt) => opt.value !== securityQuestionOne.selected
        );
      }

      function securityQuestionsAPISchema() {
        return [
          {
            key: securityQuestionOne.selected,
            value: securityQuestionOne.answer,
          },
          {
            key: securityQuestionTwo.selected,
            value: securityQuestionTwo.answer,
          },
        ];
      }

      return {
        removeQuestionFromListOne,
        removeQuestionFromListTwo,
        securityQuestionOne,
        securityQuestionTwo,
        securityQuestionsAPISchema,
      };
    }
    const linksOpened = ref({
      privacyOpened: false,
      feesOpened: false,
      holderOpened: false,
    });

    // Consent
    function useConsent() {
      const termsConditions = ref(false);
      const noticesAndDisclosures = ref(false);
      const privacyPolicy = ref(false);
      const links = props.enrollmentData.links.map((link) => link.value);
      const date = new Date();
      const formattedDate = format(date, "yyyy-MM-dd");
      const formattedTime = format(date, "kk:mm:ss");
      const acceptedDate = `${formattedDate}T${formattedTime}`;

      const computedPolicy = computed(() =>
        linksOpened.value.privacyOpened ? privacyPolicy.value : false
      );
      const computedTerms = computed(() =>
        linksOpened.value.feesOpened && linksOpened.value.holderOpened
          ? termsConditions.value
          : false
      );

      function consentAPISchema() {
        return [
          {
            consentGroupID: "1",
            consentGroupName: "Acquisition Consents",
            displayText: "Acquisition Consents",
            consents: [
              {
                consentId: "2",
                consentName: "Privacy Policy",
                displayText: "User Privacy agreement",
                accepted: termsConditions.value,
                acceptedDate,
              },
              {
                consentId: "7",
                consentName: "Cardholder Agreement",
                displayText: "Cardholder agreement",
                accepted: termsConditions.value,
                acceptedDate,
              },
              {
                consentId: "8",
                consentName: "Schedule of Fees",
                displayText: "Fee Schedule agreement",
                accepted: termsConditions.value,
                acceptedDate,
              },
            ],
          },
          {
            consentGroupID: "2",
            consentGroupName: "Electronic Consents",
            displayText: "Electronic Consents",
            consents: [
              {
                consentId: "1",
                consentName: "eConsent",
                displayText: "E-Consent",
                accepted: noticesAndDisclosures.value,
                acceptedDate,
              },
            ],
          },
        ];
      }

      return {
        termsConditions,
        privacyPolicy,
        noticesAndDisclosures,
        links,
        consentAPISchema,
        computedPolicy,
        computedTerms,
      };
    }

    const showConfirmCancel = ref(false);

    const personProfile = usePersonProfile();
    const userProfile = useUserProfile();
    const securityQuestions = useSecurityQuestions();
    const consent = useConsent();

    // Submit
    function useSubmit() {
      const { enrollment, enrollLoading } = useMoneyNetworkEnrollment();

      async function submit() {
        form.value?.validate().then(({ valid: isValid }) => {
          if (!isValid) return;
          const apiSchema = createApiSchema();
          enrollment(apiSchema, props.enrollmentData);
        });
      }

      function createApiSchema() {
        return {
          userProfile: userProfile.userProfileAPISchema(),
          personProfile: personProfile.personProfileAPISchema(),
          securityQuestionAnswers:
            securityQuestions.securityQuestionsAPISchema(),
          consentGroups: consent.consentAPISchema(),
          brand: getPayoutSrc.value,
          transactionId: payoutStore?.selected?.transactionId,
        };
      }

      return { submit, enrollLoading };
    }

    const rules = { minLength, required, inputPattern, email };

    return {
      ...personProfile,
      ...userProfile,
      ...securityQuestions,
      ...consent,
      ...useSubmit(),
      ...rules,
      showConfirmCancel,
      getPayoutSrc,
      isStreetAddressDisabled,
      moneynetworkPolicyURL,
      linksOpened,
      linkClicked,
      form,
      xs,
    };
  },

  methods: {
    privacyOpenedClick() {
      this.linksOpened.privacyOpened = true;
    },
    feesOpenedClick() {
      this.linksOpened.feesOpened = true;
      this.onAgreementChecked(true);
    },
    holderOpenedClick() {
      this.linksOpened.holderOpened = true;
      this.onAgreementChecked(true);
    },
    onAgreementChecked(v) {
      if (this.computedTerms && v) {
        this.$refs.form.resetValidation();
        // this.$refs.agreementbox.resetValidation();
      }
    },
  },
};
</script>

<template>
  <VRow class="pb-12">
    <MoneyNetworkLogo />
    <VForm ref="form" class="pt-6" :class="{ 'ml-4': xs }">
      <VRow>
        <VCol cols="12" md="6">
          <NameTextField
            v-model="personProfile.firstName"
            :disabled="isStreetAddressDisabled"
            :label="$t('NewMoneyNetworkForm.firstName')"
            data-test="first-name"
          />
        </VCol>
        <VCol cols="12" md="6">
          <NameTextField
            v-model="personProfile.lastName"
            :disabled="isStreetAddressDisabled"
            :label="$t('NewMoneyNetworkForm.lastName')"
            data-test="last-name"
          />
        </VCol>
      </VRow>

      <div>
        <StreetAddressForm
          :disabled="isStreetAddressDisabled"
          :form="personProfile.address"
        />
      </div>

      <v-radio-group v-model="showShippingAddress" mandatory row>
        <v-radio
          :value="false"
          :label="$t('NewMoneyNetworkForm.shipToAboveAddress')"
          data-test="use-above-address"
        />

        <v-radio
          :value="true"
          :label="$t('NewMoneyNetworkForm.enterShippingAddress')"
          data-test="shipping-address"
        />
      </v-radio-group>

      <div v-if="showShippingAddress" class="py-6" data-test="shipping-details">
        <VDivider />
        <div class="pt-4">
          {{ $t("NewMoneyNetworkForm.shippingDetails") }}
        </div>
        <StreetAddressForm :form="personProfile.shippingAddress" />
        <VDivider />
      </div>

      <VRow class="pt-6" no-gutters>
        <VCol class="pr-md-4" cols="12" md="6">
          <AppTextField
            v-model.trim="userProfile.email"
            disabled
            :label="$t('NewMoneyNetworkForm.email')"
            :rules="[required(), email()]"
            data-test="email"
            aria-required="true"
          />
        </VCol>
      </VRow>

      <VRow no-gutters>
        <VCol
          v-if="getPayoutSrc != 'US_DEBIT'"
          class="pr-md-4"
          cols="12"
          md="6"
        >
          <SecureTextField
            v-model="personProfile.ssn"
            mask="###-##-####"
            :rules="[
              required(),
              inputPattern(
                /^(\d{3}-(\d{2})-(\d{4}))/,
                $t('NewMoneyNetworkForm.validSSN')
              ),
            ]"
            :label="$t('NewMoneyNetworkForm.ssn')"
            :maxlength="11"
            data-test="ssn"
            aria-required="true"
          />
        </VCol>
        <VCol
          :class="getPayoutSrc != 'US_DEBIT' ? 'pr-md-4' : 'pr-md-2'"
          cols="12"
          md="6"
        >
          <DateTextField
            v-model="personProfile.dob"
            :label="$t('NewMoneyNetworkForm.dob')"
            :rules="[required(), dobDateRange]"
            data-test="dob"
            aria-required="true"
          />
        </VCol>
      </VRow>

      <VRow no-gutters>
        <VCol class="pr-md-4" cols="12" md="6">
          <PhoneNumberField
            v-model="personProfile.primaryPhone"
            :label="$t('NewMoneyNetworkForm.primaryPhone')"
            :value="personProfile.primaryPhone"
            data-test="primary-phone-number"
            aria-required="true"
          />
        </VCol>
        <VCol class="pl-md-4" cols="12" md="6">
          <PhoneNumberField
            v-if="!hideMobile"
            v-model="personProfile.mobilePhone"
            :label="$t('NewMoneyNetworkForm.mobilePhone')"
            :value="personProfile.mobilePhone"
            data-test="mobile-phone-number"
            aria-required="true"
          />
        </VCol>
      </VRow>

      <VRow>
        <VCol cols="12">
          <VCheckbox
            v-model="hideMobile"
            :label="$t('NewMoneyNetworkForm.primaryIsMobile')"
            :aria-label="$t('NewMoneyNetworkForm.primaryIsMobile')"
            data-test="primary-phone-mobile"
            @change="personProfile.mobilePhone = ''"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol class="pr-md-4" cols="12" md="6">
          <AppTextField
            v-model="userProfile.userName"
            :label="$t('NewMoneyNetworkForm.userName')"
            :rules="[
              required(),
              onlyAlphaNumeric,
              minLength(6, $t('NewMoneyNetworkForm.userNameLength')),
            ]"
            data-test="user-name"
            aria-required="true"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol class="pr-md-4" cols="12" md="6">
          <PasswordField
            v-model="userProfile.pw"
            :label="$t('NewMoneyNetworkForm.pw')"
            aria-required="true"
          />
        </VCol>
        <VCol class="pl-md-4" cols="12" md="6">
          <SecureTextField
            v-model="userProfile.confirmPw"
            :label="$t('NewMoneyNetworkForm.confirmPw')"
            :rules="[required(), passwordsMatch]"
            data-test="confirm-pw"
            aria-required="true"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="12" md="6" class="pr-md-4 usedebitQuestion">
          <v-select
            id="usedebitselect_1"
            v-model="securityQuestionOne.selected"
            :items="securityQuestionOne.options"
            item-title="text"
            item-value="value"
            :rules="[required()]"
            :label="$t('NewMoneyNetworkForm.securityQuestion1')"
            data-test="first-question"
            aria-required="true"
            @update:model-value="removeQuestionFromListTwo"
          />
        </VCol>
        <VCol cols="12" md="6" class="pl-md-4">
          <SecureTextField
            v-model="securityQuestionOne.answer"
            :label="$t('NewMoneyNetworkForm.answer1')"
            :rules="[required()]"
            data-test="first-answer"
            aria-required="true"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="12" md="6" class="pr-md-4 usedebitQuestion">
          <v-select
            id="usedebitselect_2"
            v-model="securityQuestionTwo.selected"
            :items="securityQuestionTwo.options"
            item-title="text"
            item-value="value"
            :rules="[required()]"
            :label="$t('NewMoneyNetworkForm.securityQuestion2')"
            data-test="second-question"
            aria-required="true"
            @update:model-value="removeQuestionFromListOne"
          />
        </VCol>
        <VCol cols="12" md="6" class="pl-md-4">
          <SecureTextField
            v-model="securityQuestionTwo.answer"
            :label="$t('NewMoneyNetworkForm.answer2')"
            :rules="[required()]"
            data-test="second-answer"
            aria-required="true"
          />
        </VCol>
      </VRow>
      <VRow class="mt-0">
        <VCol cols="12">
          <div class="font-weight-bold">
            {{
              `${$t("NewMoneyNetworkForm.note")}: ${$t(
                "NewMoneyNetworkForm.noteContent"
              )}.`
            }}
          </div>
        </VCol>
      </VRow>

      <VRow class="mt-0">
        <VCol cols="12">
          <VCheckbox
            v-model="noticesAndDisclosures"
            class="pt-0 mt-0"
            :label="$t('NewMoneyNetworkForm.noticesAndDisclosures')"
            :rules="[required()]"
            :aria-label="$t('NewMoneyNetworkForm.noticesLabel')"
            data-test="notices-disclosures"
            aria-required="true"
          />
        </VCol>
      </VRow>

      <VRow>
        <VCol cols="12">
          <div class="d-flex">
            <VCheckbox
              id="doublechk-box"
              ref="agreementbox"
              v-model="termsConditions"
              :input-value="computedTerms"
              class="pt-0 mt-0"
              :rules="[
                required(),
                linkClicked(
                  linksOpened.holderOpened,
                  $t('NewMoneyNetworkForm.reviewAccountAgreement')
                ),
                linkClicked(
                  linksOpened.feesOpened,
                  $t('NewMoneyNetworkForm.reviewFeeAgreement')
                ),
              ]"
              v-bind="$attrs"
              :aria-label="$t('NewMoneyNetworkForm.termsLabel')"
              data-test="fees-holder-check"
              aria-required="true"
              @change="onAgreementChecked($event)"
            >
              <template #label>
                <i18n-t
                  keypath="NewMoneyNetworkForm.accountAndTransactionLimit"
                  tag="div"
                >
                  <a
                    :href="links[0]"
                    class="text-decoration-underline fiserv-dark-blue--text"
                    target="_blank"
                    data-test="holder-click"
                    @click="holderOpenedClick()"
                    @click.stop
                  >
                    {{ $t("NewMoneyNetworkForm.accountHolderAgreement") }}
                  </a>

                  <a
                    :href="links[2]"
                    class="text-decoration-underline fiserv-dark-blue--text"
                    target="_blank"
                    data-test="fees-click"
                    @click="feesOpenedClick()"
                    @click.stop
                  >
                    {{ $t("NewMoneyNetworkForm.feeAndTransactionLimit") }}
                  </a>
                </i18n-t>
              </template>
            </VCheckbox>
          </div>
        </VCol>
      </VRow>

      <VRow>
        <VCol cols="12">
          <div class="d-flex">
            <VCheckbox
              v-model="privacyPolicy"
              class="pt-0 mt-0"
              :rules="[required()]"
              v-bind="$attrs"
              :aria-label="$t('NewMoneyNetworkForm.privacyPolicy')"
              data-test="terms-conditions"
              aria-required="true"
            >
              <template #label>
                <i18n-t keypath="NewMoneyNetworkForm.privacyNotice" tag="div">
                  <a
                    :href="moneynetworkPolicyURL"
                    class="text-decoration-underline fiserv-dark-blue--text"
                    target="_blank"
                    data-test="privacy-click"
                    @click.stop
                  >
                    {{ $t("NewMoneyNetworkForm.privacyPolicy") }}
                  </a>
                </i18n-t>
              </template>
            </VCheckbox>
          </div>
        </VCol>
      </VRow>

      <VRow class="mt-0">
        <VCol>
          <div class="font-weight-bold">
            {{ $t("NewMoneyNetworkForm.importantInfoForOpeningNewAccount") }}
          </div>
          <div class="pt-2">
            {{ $t("NewMoneyNetworkForm.importantInfoContent") }}
          </div>
        </VCol>
      </VRow>
      <VRow class="pt-4">
        <VCol cols="12">
          <div class="d-flex justify-center">
            <VBtn
              class="mr-4 mr-md-6"
              tile
              elevation="0"
              color="primary"
              min-width="125"
              data-test="enroll"
              :loading="enrollLoading"
              @click="submit"
            >
              {{ $t("NewMoneyNetworkForm.enroll") }}
            </VBtn>
            <VBtn
              class="ml-4 ml-md-6"
              tile
              variant="outlined"
              color="primary"
              min-width="125"
              data-test="cancel"
              @click="showConfirmCancel = true"
            >
              {{ $t("NewMoneyNetworkForm.cancel") }}
            </VBtn>
          </div>
        </VCol>
      </VRow>
    </VForm>
    <PayoutMethodsConfirmCancelDialog v-model="showConfirmCancel" />
  </VRow>
</template>
<style>
.usedebitQuestion .v-label.v-field-label {
  --v-field-padding-start: 0 !important;
}
</style>
