<template>
  <div class="secondary--text" data-test="new-payout-type-time-frame">
    <!-- {{ timeFrame }} -->
    {{ $t(timeFrame) }}
  </div>
</template>

<script>
export default {
  name: "NewPayoutMethodCardTimeframe",

  props: {
    timeFrame: { type: String, required: true },
  },
};
</script>
