<template>
  <div>
    <div class="font-weight-bold mt-6">
      {{ $t("RejectPayout.areYouSure") }}
    </div>

    <payout-summary
      v-if="payout && isUser"
      :payout="payout"
      data-test="payout-summary"
    />

    <div class="mt-6">
      <v-form ref="form" v-model="valid">
        <label for="reason">{{ $t("RejectPayout.reason") }} </label>
        <v-textarea
          id="reason"
          v-model="form.comments"
          variant="outlined"
          counter="150"
          maxlength="150"
          :rules="[textAreaRules]"
          data-test="text-area"
          aria-required="true"
        ></v-textarea>
      </v-form>
    </div>
    <div class="d-flex justify-center align-center">
      <v-btn
        class="mx-2 custom_min_width_100"
        tile
        elevation="0"
        color="primary"
        :disabled="loading"
        :loading="loading"
        data-test="reject-with-comment-button"
        @click="reject"
      >
        {{ $t("RejectPayout.reject") }}
      </v-btn>
      <v-btn
        class="mx-2 custom_min_width_100"
        tile
        variant="outlined"
        elevation="0"
        color="primary"
        data-test="cancel-button"
        @click="cancel"
      >
        {{ $t("RejectPayout.cancel") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PayoutSummary from "@/components/payouts/PayoutSummary";
import PayoutDetails from "@/components/payouts/PayoutDetails";
import ConfirmRejectPayout from "@/components/payouts/ConfirmRejectPayout";
import { useAuthStore } from "@/stores/auth";
import { usePayoutStore } from "@/stores/payout";
import { useAppStore } from "@/stores/app";
import { resolveErrorMsg } from "@/utils/error-handler";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDynamicContent } from "@/composables/dynamic-content";
import { getRecipient } from "@/utils/payout";

export default {
  name: "RejectPayout",

  components: { PayoutSummary },

  setup() {
    const payoutStore = usePayoutStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    useTitle(i18n.global.t("RejectPayout.title"));

    const { setContent } = useDynamicContent();

    return {
      payoutStore,
      authStore,
      appStore,
      setContent,
    };
  },

  data() {
    return {
      loading: false,

      showRejectContent: false,

      valid: false,

      form: {
        comments: "",
      },
    };
  },

  computed: {
    isUser() {
      return this.authStore.isUser;
    },

    payout() {
      return this.payoutStore.selected;
    },

    isMfaEnabled() {
      return this.authStore.session.mfaEnabled;
    },
  },

  methods: {
    textAreaRules(v) {
      return (
        (v && v.length <= 150 && v.replace(/\s/g, "") !== "") ||
        i18n.global.t("RejectPayout.reasonRequired")
      );
    },

    cancel() {
      this.isUser
        ? this.setContent(PayoutDetails)
        : this.$router.push({ name: "guest-payout" });
    },

    refreshPayouts() {
      this.payoutStore
        .fetchAvailable()
        .catch(() =>
          this.appStore.showError(
            i18n.global.t("RejectPayout.networkError.available")
          )
        );

      this.payoutStore
        .fetchHistory(this.payoutStore.historyPagination.pages.currentPage)
        .catch(() =>
          this.appStore.showError(
            i18n.global.t("RejectPayout.networkError.history")
          )
        );

      if (this.$route.name === "dashboard") {
        this.payoutStore
          .fetchLatest()
          .catch(() =>
            this.appStore.showError(
              i18n.global.t("RejectPayout.networkError.latest")
            )
          );
      }
    },

    async reject() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return;

      this.loading = true;
      const recipient = getRecipient(this.payout);

      try {
        await this.payoutStore.reject({
          recipientId: this.authStore.user.recipientId,
          transactionId: this.payout.transactionId,
          paymentId: recipient.payments.paymentId,
          requestBody: {
            action: "reject",
            description: this.form.comments,
          },
        });

        await this.payoutStore.fetchSelected();

        if (this.isUser) {
          this.setContent(ConfirmRejectPayout);
          this.refreshPayouts();
        } else {
          await this.$router.push({
            name: "guest-payout-rejected",
            query: {
              reason: this.form.comments,
              mfaEnabled: this.isMfaEnabled,
            },
          });
        }
      } catch (err) {
        const msg = resolveErrorMsg(err, "RejectPayout.default");
        this.appStore.showError(msg);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
