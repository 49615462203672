<script>
import { computed } from "vue";
import merchants from "@/utils/merchants";

export default {
  props: {
    customFields: { type: Array, default: () => [] },
    merchant: { type: String, required: true },
  },
  setup(props) {
    const sanitized = computed(() => {
      return props.customFields
        .filter((cf) => {
          const exclusions =
            merchants[props.merchant.toLowerCase()]?.customFieldExclusions ??
            [];

          return !exclusions.includes(cf.key.toLowerCase());
        })
        .map((cf) => {
          const key = cf.key.replace(/[^A-Za-z\d]/g, " ");
          const value = cf.valueAsList ? cf.valueAsList.join(", ") : cf.value;
          return { key, value };
        });
    });

    return {
      sanitized,
    };
  },
};
</script>

<template>
  <VRow>
    <VCol
      v-for="(field, index) in sanitized"
      :key="index"
      cols="12"
      sm="6"
      data-test="detail-item"
    >
      <div class="font-weight-bold text-capitalize" data-test="detail-label">
        {{ field.key }}:
      </div>
      <div data-test="detail-value">
        {{ field.value }}
      </div>
    </VCol>
  </VRow>
</template>
