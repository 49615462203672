import "@/plugins/composition";
import pinia from "@/plugins/pinia";
import "@/plugins/focus-loop";
//import "@/plugins/portal";
import "@/plugins/skip-to";
import vuetify from "@/plugins/vuetify";
import { i18n } from "@/plugins/i18n";
//import "@/plugins/announcer";
import "@/plugins/router";
// import "@/plugins/json-excel";

export default {
  i18n,
  pinia,
  vuetify,
};
