<template>
  <div class="d-flex flex-column align-center justify-center">
    <v-spacer class="pb-10" />

    <svg-icon
      type="mdi"
      :path="mdiCashRemove"
      class="error"
      size="80"
    ></svg-icon>

    <div class="mb-10 mt-5">
      <div class="text-md-h3 text-h4 text-center" role="heading" aria-level="3">
        {{ $t("ConfirmRejectPayout.payoutRejected") }}
      </div>
    </div>

    <div class="mb-2">
      <span class="font-weight-bold mr-1">
        {{ $t("ConfirmRejectPayout.processed") }}
      </span>
      <span data-test="rejected-processed-date">{{ processedDate() }}</span>
    </div>

    <div v-if="payout" class="w-full d-flex flex-column align-center">
      <div class="mb-2 font-weight-bold">
        {{ $t("ConfirmRejectPayout.tid") }}
      </div>

      <div class="mb-6" data-test="rejected-tx-id">
        {{ payout.transactionId }}
      </div>

      <div class="w-full max-w-500">
        <label for="reject-reason-textarea">
          {{ $t("ConfirmRejectPayout.reason") }}
        </label>
        <v-textarea
          id="reject-reason-textarea"
          disabled
          variant="outlined"
          :value="payout.reason"
        />

        <multi-recipient-status-other v-if="multiRecipient" :payout="payout" />
      </div>
    </div>

    <v-btn
      class="my-4"
      tile
      variant="outlined"
      elevation="0"
      color="primary"
      width="125"
      data-test="done-button"
      @click="closeRejectDialog"
    >
      {{ $t("ConfirmRejectPayout.done") }}
    </v-btn>
  </div>
</template>

<script>
import { mdiCashRemove } from "@mdi/js";
import MultiRecipientStatusOther from "@/components/multi-recipient/MultiRecipientStatusOther";
import { format } from "date-fns";
import { usePayoutStore } from "@/stores/payout";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { isMultiRecipient } from "@/utils/payout";

export default {
  name: "ConfirmRejectPayout",

  components: { MultiRecipientStatusOther },

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const payoutStore = usePayoutStore();

    useTitle(i18n.global.t("ConfirmRejectPayout.confirmationTitle"));

    return {
      payoutStore,
      dialogPanelStore,
    };
  },

  data() {
    return {
      mdiCashRemove,
    };
  },

  computed: {
    payout: {
      get() {
        return this.payoutStore.selected;
      },

      set(v) {
        this.payoutStore.selected = v;
      },
    },

    multiRecipient() {
      return isMultiRecipient(this.payout);
    },
  },

  methods: {
    closeRejectDialog() {
      this.dialogPanelStore.hide();
      this.payout = null;
    },

    processedDate() {
      return format(new Date(), String(i18n.global.t("dateFormat.short")));
    },
  },
};
</script>

<style scoped>
.max-w-500 {
  max-width: 500px;
}
</style>
