import {
  commitAccessKey,
  isGuest,
  isUser,
  getNotificationData,
  fetchMerchantConfigData,
} from "@/router/guards";
import DialogPanelLayout from "@/layouts/DialogPanelLayout";
import RegisteredLayout from "@/layouts/RegisteredLayout";
import { i18n } from "@/plugins/i18n";
import multiguard from "vue-router-multiguard";
import DefaultLayout from "@/layouts/DefaultLayout";
import { defineAsyncComponent } from "vue";

export default [
  // DASHBOARD
  {
    path: "/",
    name: "dashboard",
    meta: {
      layout: DialogPanelLayout,
      title: i18n.global.t("routes.dashboard.title"),
    },
    // beforeEnter: isUser,
    beforeEnter: multiguard([
      isUser,
      getNotificationData,
      fetchMerchantConfigData,
    ]),
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },

  // PAYOUTS
  {
    path: "/payouts",
    name: "payouts",
    meta: {
      layout: DialogPanelLayout,
      title: i18n.global.t("routes.payouts.title"),
    },
    beforeEnter: isUser,
    component: () =>
      import(/* webpackChunkName: "payouts" */ "../views/Payouts.vue"),
    props: (route) => ({ initActive: route.query.active }),
  },

  // PROFILE
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: RegisteredLayout,
      title: i18n.global.t("routes.profile.title"),
    },
    beforeEnter: isUser,
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },

  // AUTH
  {
    path: "/auth",
    beforeEnter: getNotificationData,
    component: defineAsyncComponent(() =>
      import(/* webpackChunkName: "auth" */ "../layouts/AuthLayout.vue")
    ),
    children: [
      {
        path: "sign-in",
        name: "sign-in",
        meta: { title: i18n.global.t("routes.signIn.title") },
        component: () =>
          import(/* webpackChunkName: "sign-in" */ "../views/auth/SignIn.vue"),
      },
      {
        path: "reset-password",
        name: "reset-password",
        meta: { title: i18n.global.t("routes.resetPassword.title") },
        component: () =>
          import(
            /* webpackChunkName: "reset-pw" */ "../views/auth/ResetPassword.vue"
          ),
      },
      {
        path: "registration",
        name: "registration",
        beforeEnter: isGuest,
        meta: { title: i18n.global.t("routes.registration.title") },
        component: () =>
          import(
            /* webpackChunkName: "registration" */ "../views/auth/Registration.vue"
          ),
      },
    ],
  },

  // SESSION ENDED
  {
    path: "/session-ended",
    name: "session-ended",
    meta: {
      layout: DefaultLayout,
      title: i18n.global.t("routes.sessionEnded.title"),
    },
    props: (route) => ({
      guest: route.query.guest === "true",
      source: route.query.source,
    }),
    component: () =>
      import(
        /* webpackChunkName: "session-ended" */ "../views/auth/SessionEnded.vue"
      ),
  },

  // LEGACY ENTRYPOINT REDIRECT
  {
    path: "/authentication/guest/:ak",
    name: "legacy-redirect",
    redirect: (to) => ({ name: "guest-auth", params: { ak: to.params.ak } }),
  },

  // GUEST AUTH
  {
    path: "/guest-auth/:ak",

    component: defineAsyncComponent(() =>
      import(/* "guest-auth-layout" */ "../layouts/GuestAuthLayout.vue")
    ),
    children: [
      {
        path: "",
        name: "guest-auth",
        beforeEnter: multiguard([commitAccessKey, getNotificationData]),
        //  beforeEnter: commitAccessKey,
        meta: { title: i18n.global.t("routes.guestAuth.title") },
        component: () =>
          import(/* "guest-auth" */ "../views/guest/GuestAuth.vue"),
      },
      {
        path: "payment-cancelled",
        name: "guest-payment-cancelled",
        meta: { title: i18n.global.t("routes.guestPaymentCancelled.title") },
        component: () =>
          import(
            /* "guest-payment-cancelled" */ "../views/guest/GuestPaymentCancelled.vue"
          ),
      },
    ],
  },

  // GUEST PAYOUT
  {
    path: "/guest-payout/:ak",
    beforeEnter: multiguard([commitAccessKey, isGuest]),
    component: defineAsyncComponent(() =>
      import(/* "guest-payout-layout" */ "../layouts/GuestPayoutLayout.vue")
    ),
    children: [
      {
        path: "",
        name: "guest-payout",
        meta: { title: i18n.global.t("routes.guestPayout.title") },
        component: () =>
          import(/* "guest-payout" */ "../views/guest/GuestPayout.vue"),
      },
      {
        path: "reject-payout",
        name: "guest-reject-payout",
        meta: {
          title: i18n.global.t("routes.guestRejectPayout.title"),
        },
        component: () =>
          import(
            /* "guest-reject-payout" */ "../views/guest/GuestRejectPayout.vue"
          ),
      },
      {
        path: "payout-rejected",
        name: "guest-payout-rejected",
        meta: {
          title: i18n.global.t("routes.guestPayoutRejected.title"),
        },
        props: (route) => ({
          reason: route.query.reason,
          mfaEnabled: route.query.mfaEnabled === "true",
        }),
        component: () =>
          import(
            /* "guest-payout-rejected" */ "../views/guest/GuestPayoutRejected.vue"
          ),
      },
    ],
  },

  // Errors
  {
    path: "/error",
    name: "error",
    meta: {
      layout: RegisteredLayout,
      title: i18n.global.t("routes.error.title"),
    },
    props: (route) => {
      if (
        route.query.code &&
        i18n.global.te(`errorCodes.genericErrors.${route.query.code}`)
      ) {
        return {
          message: i18n.global.t(
            `errorCodes.genericErrors.${route.query.code}`
          ),
        };
      }

      return {
        message: i18n.global.t("Error.default"),
      };
    },
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error.vue"),
  },

  // Info
  {
    path: "/info",
    name: "info",
    meta: {
      layout: RegisteredLayout,
      title: i18n.global.t("routes.info.title"),
    },
    component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue"),
  },

  // Faq
  {
    path: "/faq",
    name: "faq",
    meta: {
      layout: RegisteredLayout,
      title: i18n.global.t("routes.faq.title"),
    },
    component: () => import(/* webpackChunkName: "info" */ "../views/Faq.vue"),
  },

  // NotFound
  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    meta: {
      layout: RegisteredLayout,
      title: i18n.global.t("routes.notFound.title"),
    },
    props: {
      statusCode: "404",
      message: i18n.global.t("NotFound.notFound"),
    },
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
];
