<template>
  <v-app :style="{ background: background }">
    <the-skip-links />

    <the-nav-bar />

    <the-main class="main">
      <announcement-banner-wrapper></announcement-banner-wrapper>

      <router-view />

      <dialog-panel :scrollable-background="true" />

      <the-footer />
    </the-main>

    <the-mobile-bottom-nav-bar />

    <the-error-alert />

    <activity-tracker />
  </v-app>
</template>

<script>
import TheErrorAlert from "@/components/TheErrorAlert";
import TheFooter from "@/components/TheFooter";
import TheMobileBottomNavBar from "@/components/TheMobileBottomNavBar";
import ActivityTracker from "@/components/auth/ActivityTracker";
import TheMain from "@/components/TheMain";
import TheSkipLinks from "@/components/TheSkipLinks";
import DialogPanel from "@/components/DialogPanel";
import AnnouncementBannerWrapper from "@/components/AnnouncementBannerWrapper.vue";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { useDisplay } from "vuetify";

import TheNavBar from "@/components/TheNavBar";

export default {
  name: "DialogPanelLayout",

  components: {
    DialogPanel,
    TheSkipLinks,
    TheMain,
    TheMobileBottomNavBar,
    TheFooter,
    TheErrorAlert,
    TheNavBar,
    ActivityTracker,
    AnnouncementBannerWrapper,
  },

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const { mdAndUp } = useDisplay();
    return {
      dialogPanelStore,
      mdAndUp,
    };
  },

  computed: {
    background() {
      return this.mdAndUp
        ? "none"
        : this.$vuetify.theme.themes.light.colors.background;
    },
  },
};
</script>

<style scoped>
@media (max-width: 960px) {
  .main::after,
  .main::before {
    content: none !important;
  }
}

.main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 57.5%;
  height: 100%;
  background-color: rgb(var(--v-theme-background));
  z-index: -1;
}

.main::after {
  content: "";
  position: absolute;
  top: 0;
  left: 57.5%;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--v-theme-sidebar));
  z-index: -1;
}
</style>
