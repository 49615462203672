import { i18n } from "@/plugins/i18n";
export default [
  {
    code: "001",
    value: i18n.global.t("TheFooter.copyright_001"),
  },
  {
    code: "002",
    value: i18n.global.t("TheFooter.copyright_002"),
  },
];
