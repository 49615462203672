<template>
  <vue-skip-to
    class="hideSkipLinks"
    style="z-index: 1000; margin-top: -9px"
    :list-label="$t('TheSkipLinks.listLabel')"
    :to="[
      { anchor: '#main', label: $t('TheSkipLinks.main') },
      {
        anchor: xs ? '#mobile-bottom-nav' : '#nav',
        label: $t('TheSkipLinks.nav'),
      },
      { anchor: '#footer', label: $t('TheSkipLinks.footer') },
    ]"
  ></vue-skip-to>
</template>

<script>
import { useDisplay } from "vuetify";
export default {
  name: "TheSkipLinks",
  setup() {
    const { xs } = useDisplay();
    return { xs };
  },
};
</script>
<style>
@media (min-width: 320px) and (max-width: 480px) {
  .hideSkipLinks {
    visibility: hidden;
  }
}
</style>
