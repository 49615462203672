import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
import { useDynamicContent } from "@/composables/dynamic-content";
import { ref } from "vue";
import ConfirmDisbursePayout from "@/components/payouts/available/disbursements/ConfirmDisbursePayout";
import GuestTransferSuccess from "@/components/guest/GuestTransferSuccess";
import { payoutMethodDetail } from "@/utils/payout-methods";
import { encryptPayoutMethod } from "@/utils/encryption";
import { getRecipient } from "@/utils/payout";
import get from "lodash/get";
import TimeoutError from "@/components/payouts/available/disbursements/TimeoutError";
import { resolveErrorMsg } from "@/utils/error-handler";
import { i18n } from "@/plugins/i18n";
import cloneDeep from "lodash/cloneDeep";
import { v4 } from "uuid";

export function useDisburse() {
  const { setContent } = useDynamicContent();

  const disburseLoading = ref(false);
  const disabled = ref(false);
  const error = ref("");

  const disburse = (classification) =>
    handleDisburse(
      classification,
      disburseLoading,
      disabled,
      error,
      setContent
    );

  return {
    disabled,
    error,
    disburseLoading,
    disburse,
  };
}

async function handleDisburse(
  classification,
  disburseLoading,
  disabled,
  error,
  setContent
) {
  disburseLoading.value = true;

  const payoutStore = usePayoutStore();
  const authStore = useAuthStore();

  const isUser = authStore.isUser;
  const recipient = getRecipient(payoutStore.selected);
  const accountDetails = getDisbursePaymentDetails(classification, authStore);
  const tmxMetics = payoutStore.getTmxInfo;
  let doc;
  if (tmxMetics.tmxEnabled) {
    doc = document.createElement("script");
    const fraudId = v4();
    payoutStore.setTmxId(fraudId);
    const profilingStr = payoutStore.getUriWithParam(
      tmxMetics.url + "/fp/tags.js",
      {
        org_id: tmxMetics.organizationId,
        policy: tmxMetics.policy,
        session_id: fraudId,
      }
    );
    window.tmx_profiling_started = undefined;
    doc.setAttribute("src", profilingStr);
    document.head.appendChild(doc);
  }
  try {
    await payoutStore.disburse({
      paymentId: recipient.payments.paymentId,
      accountDetails,
    });
    payoutStore.setsecurityCodeRequired(false);
    payoutStore.setdisableCardExpiry(false);
    refreshPayouts(authStore, payoutStore);
    setContent({
      component: isUser ? ConfirmDisbursePayout : GuestTransferSuccess,
      attrs: isUser
        ? { checkImageUrl: recipient.checkImageUrl }
        : {
            checkImageUrl: recipient.checkImageUrl,
            mfaEnabled: authStore.session.mfaEnabled === "true",
          },
    });

    if (tmxMetics.tmxEnabled) {
      doc.remove();
      let frame = document.getElementById("tmx_tags_iframe");
      frame.parentNode.removeChild(frame);
    }
  } catch (err) {
    const code = get(err, "response.data.code");
    const payoutMethodStore = usePayoutMethodStore();
    const disbursementType = payoutMethodStore.selected.source;
    if (code === "400412" && disbursementType === "RTP") {
      payoutStore.setRTPaccountCability(true);
      return;
    }

    if (code === "400073" || code === "400063") {
      disabled.value = true;
      error.value = i18n.global.t(`errorCodes.disburse.DEBIT.${code}`);
      isUser && payoutStore.fetchAvailable();
      return;
    }

    if (code === "400213") {
      refreshPayouts(authStore, payoutStore);
      setContent({
        component: TimeoutError,
        attrs: { msgContent: i18n.global.t("AcceptPayout.timeoutErrorMsg") },
      });
    }

    if (code === "500000" && disbursementType === "ECHECK") {
      refreshPayouts(authStore, payoutStore);
      setContent({
        component: TimeoutError,
        attrs: { msgContent: i18n.global.t("ECheckForm.payoutErrorMsg") },
      });
    }

    const msg = resolveErrorMsg(err, `disburse.${disbursementType}`);
    const appStore = useAppStore();
    appStore.showError(msg);
  } finally {
    disburseLoading.value = false;
  }
}

function usingPersistedPayoutMethod(payoutMethod) {
  const detail = payoutMethodDetail(payoutMethod);
  return !!detail.token;
}

function getEncryptedSelectedPayoutMethodDetails(
  payoutMethod,
  classification,
  authStore
) {
  // no need to encrypt
  if (usingPersistedPayoutMethod(payoutMethod)) {
    const recipientProfileInfo = {
      recipientId: authStore.user.recipientId,
    };

    const pm = cloneDeep(payoutMethod);
    if (classification) {
      pm.ach.classification = classification;
    }
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();
    // New Code block Added for Direct disbursement encryption
    if (
      payoutStore.getCVVfieldConfig &&
      payoutMethodStore.selected.source === "DEBIT"
    ) {
      pm.card.securityCode = payoutStore.cvvVal;
      return encryptPayoutMethod({ recipientProfileInfo, ...pm });
    } else {
      return { recipientProfileInfo, ...pm };
    }
  }

  // transient...needs encrypted
  return encryptPayoutMethod(payoutMethod);
}

function getDisbursePaymentDetails(classification, authStore) {
  const payoutMethodStore = usePayoutMethodStore();
  const payoutMethod = payoutMethodStore.selected;
  const encrypt =
    payoutMethod.source === "ACH" ||
    payoutMethod.source === "DEBIT" ||
    payoutMethod.source === "PREPAID" ||
    payoutMethod.source === "COINBASE" ||
    payoutMethod.source === "RTP";
  const pm = encrypt
    ? getEncryptedSelectedPayoutMethodDetails(
        payoutMethod,
        classification,
        authStore
      )
    : payoutMethod;

  return {
    action: "disburse",
    recipients: [pm],
  };
}

function refreshPayouts(authStore, payoutStore) {
  if (!authStore.isUser) return;

  payoutStore.refreshPayouts();
}
