<script>
import {
  expiringSoon,
  getAccountNumber,
  getDisplayName,
  payoutMethodDetail,
} from "@/utils/payout-methods";
import PayoutMethodIcon from "@/components/PayoutMethodIcon";
import { mdiClose, mdiDotsVertical, mdiStar } from "@mdi/js";
import { computed } from "vue";
import { maskNumber } from "@/filters/filters";

export default {
  components: { PayoutMethodIcon },

  props: {
    method: { type: Object, required: true },
  },
  setup(props) {
    const displayName = computed(() => getDisplayName(props.method));
    const accountNumber = computed(() => getAccountNumber(props.method));
    const isExpiringSoon = computed(() =>
      expiringSoon(props.method.expiryDate)
    );
    const isDefault = computed(() => payoutMethodDetail(props.method).default);

    return {
      mdiClose,
      mdiDotsVertical,
      mdiStar,
      displayName,
      accountNumber,
      isExpiringSoon,
      isDefault,
      maskNumber,
    };
  },

  computed: {
    payoutLabel() {
      const cardBrand = this.method?.card?.cardBrand?.toUpperCase();
      if (this.method.source === "DEBIT") {
        if (cardBrand === "MASTERCARD") {
          return "labelMaster";
        } else if (cardBrand === "VISA") {
          return "labelVisa";
        }
      }
      return "";
    },
  },
};
</script>

<template>
  <div class="d-flex align-center justify-space-between">
    <div class="d-flex align-center">
      <PayoutMethodIcon
        :payout-method="method"
        class="mr-3"
        data-test="select-option-type-icon"
      />

      <div
        class="font-weight-bold secondary--text text-capitalize text-left"
        data-test="select-option-display-name"
        :class="payoutLabel"
      >
        {{ displayName }}
      </div>
    </div>

    <div class="secondary--text text-right">
      <div class="d-flex justify-end">
        <div>{{ method.label }}</div>
        <div class="ml-2 min-w-24" />
      </div>

      <div class="d-flex align-center justify-end">
        <div
          v-if="
            method.source === 'DEBIT' &&
            method.card.expiryDate.month != undefined
          "
          class="text-small mr-3"
          :class="{
            'error--text': isExpiringSoon,
          }"
        >
          <span>{{ $t("PayoutMethodCard.expirationLabel") }}</span>
          <span data-test="select-option-expiration">{{
            `${method.card.expiryDate.month}/${method.card.expiryDate.year}`
          }}</span>
        </div>

        <div data-test="select-option-account-number">
          {{ maskNumber(accountNumber) }}
        </div>

        <div class="ml-2 min-w-24">
          <VTooltip v-if="isDefault" top>
            <template #activator="{ attrs, on }">
              <VIcon
                color="success"
                v-bind="attrs"
                data-test="select-option-default-icon"
                v-on="on"
              >
                mdi-star
              </VIcon>
            </template>

            <span data-test="select-option-default-tooltip-content">{{
              $t("PayoutMethodCard.defaultTooltip")
            }}</span>
          </VTooltip>
          <span v-if="isDefault" class="d-sr-only">{{
            $t("PayoutMethodCard.defaultTooltip")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.min-w-24 {
  min-width: 24px;
}

.labelVisa {
  margin-left: 3px;
}

.labelMaster {
  margin-left: 7.5px;
}
</style>
