import axios from "axios";
import {
  auth,
  requestId,
  unauthorizedError,
  traceId,
} from "@/api/clients/middleware"; // x

const http = axios.create({
  baseURL: process.env.VUE_APP_DDP_API_BASE_URL,
});

http.interceptors.request.use(requestId);
http.interceptors.request.use(auth);
http.interceptors.request.use(traceId);

http.interceptors.response.use((r) => r, unauthorizedError);

export default http;
