<template>
  <div data-test="accept-payout-container">
    <payout-summary :payout="payout" />

    <div class="d-flex align-center my-6">
      <div class="font-weight-bold mr-1">
        {{ $t("AcceptPayout.transferTo") }}
      </div>

      <v-tooltip
        location="top"
        max-width="300"
        content-class="tooltip-content-custom"
        data-test="transfer-to-tooltip"
        :open-on-click="true"
      >
        <template #activator="{ props }">
          <svg-icon
            class="cursor-pointer"
            color="primary"
            size="20"
            data-test="transfer-to-tooltip"
            v-bind="props"
            type="mdi"
            :path="mdiInformation"
          >
          </svg-icon>
        </template>
        <span>{{ $t("AcceptPayout.transferToTooltip") }}</span>
      </v-tooltip>

      <v-btn
        v-if="allowedPayoutMethods.length"
        text
        data-test="add-payout-method-btn-top"
        class="ml-auto d-flex"
        tile
        @click="setContent(NewPayoutMethods)"
      >
        <v-icon color="secondary">mdi-credit-card-plus</v-icon>
        <div class="secondary--text ml-2 py-4">
          {{ $t("AcceptPayout.addPayoutMethod") }}
        </div>
      </v-btn>
    </div>

    <div v-if="allowedPayoutMethods.length">
      <v-form ref="form" @submit.prevent>
        <payout-method-select
          v-model="selectedPayoutMethod"
          :methods="allowedPayoutMethods"
          :rules="[required()]"
          :show-errormsg="classificationErrorShow"
          data-test="payout-methods-select"
        />

        <ach-classification-select
          v-if="showClassificationUpdate"
          v-model="classification"
          class="mt-6"
        />

        <!--New CVC Field Added -->
        <v-row v-if="getCVVfieldConfig && payoutMethodStore.isSelectedDebit">
          <v-col cols="12" md="4" class="ml-1">
            <app-text-field
              id="cvc-number"
              v-model.trim="form.cvcNumber"
              type="password"
              :label="$t('DebitCardForm.cvcNumber')"
              :maxlength="4"
              data-test="cvc-number"
              :rules="[
                required(),
                minLength(3, $t('DebitCardForm.invalidCVCFormat')),
                numberOnly($t('DebitCardForm.invalidCVCFormat')),
              ]"
            />
          </v-col>
          <div class="d-flex align-center my-6">
            <v-tooltip
              top
              max-width="300"
              content-class="tooltip-content-custom"
              data-test="transfer-to-tooltip"
            >
              <template #activator="{ on, attrs }">
                <svg-icon
                  class="cursor-pointer"
                  color="primary"
                  size="20"
                  data-test="cvv-tooltip"
                  v-bind="attrs"
                  :path="mdiInformation"
                  type="mdi"
                  v-on="on"
                >
                </svg-icon>
              </template>
              <span>{{ $t("AcceptPayout.cvvTooltip") }}</span>
            </v-tooltip>
          </div>
        </v-row>

        <!-- Terms And Conditions Checkbox -->
        <terms-and-conditions-checkbox
          v-model="acceptTermsAndConditions"
          data-test="terms-and-conditions"
        />
      </v-form>

      <div v-if="selectedPayoutMethod && allowTransfer">
        <div v-if="payoutMethodStore.isSelectedACH">
          <div class="pt-4 font-weight-bold" data-test="estimated-arrival-text">
            <!-- {{ $t("AcceptPayout.estimatedArrivalAch") }} -->
            {{ setDDArrivalTimeFrame(selectedPayoutMethod.source) }}
          </div>

          <div class="pt-2" data-test="estimated-arrival-help">
            {{ $t("AcceptPayout.estimatedArrivalAchHelp") }}
          </div>
        </div>

        <div v-else>
          <div class="pt-4 font-weight-bold" data-test="estimated-arrival-text">
            <!-- {{ $t("AcceptPayout.estimatedArrival") }} -->
            {{ setDDArrivalTimeFrame(selectedPayoutMethod.source) }}
          </div>

          <div class="pt-2" data-test="estimated-arrival-help">
            {{ $t("AcceptPayout.estimatedArrivalHelp") }}
          </div>
        </div>
      </div>

      <div class="mt-10">
        <v-divider />

        <div class="d-flex justify-center align-center pt-6">
          <div v-if="allowTransfer">
            <v-btn
              class="mx-2 custom_min_width_100"
              tile
              elevation="0"
              color="primary"
              :disabled="disburseLoading"
              :loading="disburseLoading"
              data-test="transfer-button"
              @click="transfer"
            >
              {{ $t("AcceptPayout.submit") }}
            </v-btn>

            <v-btn
              class="mx-2 custom_min_width_100"
              tile
              variant="outlined"
              elevation="0"
              color="primary"
              data-test="cancel-button"
              @click="cancel"
            >
              {{ $t("AcceptPayout.cancel") }}
            </v-btn>
          </div>
          <v-alert v-else type="error" tile>
            <span data-test="disburse-error-message">
              {{ disburseError }}
            </span>
          </v-alert>
        </div>
      </div>
    </div>

    <v-btn
      v-else
      tile
      data-test="add-payout-method-btn"
      class="py-7 mt-6"
      block
      :loading="fetchingAllowed"
      @click="setContent(NewPayoutMethods)"
    >
      <v-icon color="secondary"> mdi-credit-card-plus </v-icon>
      <div class="secondary--text ml-2 py-4">
        {{ $t("AcceptPayout.addPayoutMethod") }}
      </div>
    </v-btn>
  </div>
</template>

<script>
import { mdiCreditCardPlus, mdiInformation } from "@mdi/js";
import PayoutSummary from "@/components/payouts/PayoutSummary";
import PayoutMethodSelect from "@/components/payouts/available/disbursements/PayoutMethodSelect";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import PayoutDetails from "@/components/payouts/PayoutDetails";
import AchClassificationSelect from "@/components/forms/AchClassificationSelect";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDynamicContent } from "@/composables/dynamic-content";
import {
  findDefaultPayoutMethod,
  payoutMethodDetail,
} from "@/utils/payout-methods";
import { resolveErrorMsg } from "@/utils/error-handler";
import { required, minLength, numberOnly } from "@/utils/rules";
import { setDDArrivalTimeFrame } from "@/utils/payout";
import { useDisburse } from "@/composables/disburse";
import { useAppStore } from "@/stores/app";
import AppTextField from "@/components/forms/AppTextField";
import { onMounted } from "vue";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";

export default {
  name: "AcceptPayout",

  components: {
    AchClassificationSelect,
    PayoutMethodSelect,
    PayoutSummary,
    AppTextField,
    TermsAndConditionsCheckbox,
  },

  setup() {
    const appStore = useAppStore();
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();

    useTitle(i18n.global.t("AcceptPayout.title"));

    const { setContent } = useDynamicContent();
    onMounted(() => {
      payoutStore.resetCVVField();
      payoutStore.resetDisableCardExpiry();
    });

    const {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
    } = useDisburse();

    return {
      appStore,
      payoutStore,
      payoutMethodStore,
      setContent,
      disburse,
      disburseLoading,
      disburseError,
      required,
      minLength,
      numberOnly,
      transferDisabled,
      setDDArrivalTimeFrame,
    };
  },

  data() {
    return {
      mdiCreditCardPlus,
      mdiInformation,
      NewPayoutMethods,
      fetchingAllowed: false,
      classification: null,
      acceptTermsAndConditions: false,
      form: {
        cvcNumber: "",
      },
      classificationErrorShow: false,
    };
  },

  computed: {
    allowTransfer() {
      return !this.transferDisabled;
    },

    payout() {
      return this.payoutStore.selected;
    },

    savedPayoutMethods() {
      return this.payoutMethodStore.persisted;
    },

    transientPayoutMethod() {
      return this.payoutMethodStore.transient;
    },

    allowed() {
      return this.payoutMethodStore.allowed;
    },
    getCVVfieldConfig() {
      return this.payoutStore.securityCodeRequired;
    },
    allowedSources() {
      return this.allowed.map((m) => m.source);
    },

    allowedPayoutMethods() {
      const allMethods = [...this.savedPayoutMethods];
      this.transientPayoutMethod && allMethods.push(this.transientPayoutMethod);
      const allowed = allMethods.filter((pm) =>
        this.allowedSources.includes(pm.source)
      );

      allowed.sort((a, b) => (payoutMethodDetail(b).default ? 1 : 0));
      return allowed;
    },

    selectedPayoutMethod: {
      get() {
        return this.payoutMethodStore.selected;
      },

      set(v) {
        this.payoutStore.savePayoutAccount = !v ? "false" : "true";
        this.payoutMethodStore.selected = v;
      },
    },

    showClassificationUpdate() {
      if (this.selectedPayoutMethod) {
        return (
          this.payoutMethodStore.isSelectedACH &&
          !this.selectedPayoutMethod.ach.classification
        );
      }
      return false;
    },
  },

  created() {
    this.fetchAllowed();
    this.getMerchantConfig();
  },

  methods: {
    fetchAllowed() {
      this.fetchingAllowed = true;
      this.payoutMethodStore
        .fetchAllowed(this.payout.transactionId)
        .catch((err) => {
          const msg = resolveErrorMsg(err, "AcceptPayout.default");
          this.appStore.showError(msg);
        })
        .finally(() => (this.fetchingAllowed = false));
    },

    async getMerchantConfig() {
      try {
        await this.payoutStore.fetchMerchantConfig(
          this.payout.merchant.originatorId
        );
      } catch (err) {
        const msg = resolveErrorMsg(err, "AcceptPayout.default");
        this.appStore.showError(msg);
      }
    },

    async transfer() {
      const valid = await this.$refs.form.validate();
      if (valid.valid && this.selectedPayoutMethod === null) {
        this.classificationErrorShow = true;
        return;
      } else if (!valid.valid && this.selectedPayoutMethod === null) {
        this.classificationErrorShow = true;
        return;
      } else if (!valid.valid && this.selectedPayoutMethod != null) {
        return;
      } else {
        this.classificationErrorShow = false;
        this.getCVVfieldConfig
          ? this.payoutStore.setCVVVal(this.form.cvcNumber)
          : "";
        this.disburse(this.classification);
      }
    },

    cancel() {
      this.appStore.hideError();

      this.setContent(PayoutDetails);

      const defaultPayoutMethod = findDefaultPayoutMethod(
        this.allowedPayoutMethods
      );
      if (defaultPayoutMethod) {
        this.payoutMethodStore.selected = defaultPayoutMethod;
      } else {
        this.payoutMethodStore.selected = null;
      }
    },
  },
};
</script>
