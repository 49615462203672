<template>
  <div
    v-if="!isBannerVisible"
    data-test="banner-card"
    class="announcement-banner mt-11 pl-5 pr-5"
  >
    <v-alert
      id="announcement_banner"
      v-model="alert"
      class="v_alert_custom v_alert_position"
      :color="alertType"
      border="start"
      elevation="2"
      prominent
      icon="mdi-bullhorn"
      :type="alertType"
      variant="outlined"
      closable
    >
      <ul>
        <li v-for="(message, index) in messages" :key="index">
          <span>{{ message }}</span>
        </li>
      </ul>
      <template #close="{ toggle }">
        <svg-icon
          type="mdi"
          data-test="closeBtn"
          class="custom_icon"
          size="24"
          :aria-label="$t('TheErrorAlert.close')"
          :title="$t('TheErrorAlert.close')"
          :path="mdiCloseCircle"
          @click="closeAlert(toggle)"
        >
        </svg-icon>
      </template>
    </v-alert>
  </div>
</template>

<script>
// Keep in mind, component names are for prototype.
import { mdiBullhorn } from "@mdi/js";
import { mdiCloseCircle } from "@mdi/js";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { ref, computed } from "vue";

export default {
  name: "BannerChild",
  props: {
    enabled: { type: Boolean, required: true, default: false },
    messages: { type: Array, required: true },
    alertType: { type: String, required: true, default: "" },
    currentView: { type: String, required: true, default: "" },
  },
  setup(props) {
    const hidden = ref(false);
    const isBannerVisible = computed(() => {
      return hidden.value;
    });
    return {
      dialogPanelStore: useDialogPanelStore(),
      hidden,
      alert: true,
      mdiBullhorn,
      mdiCloseCircle,
      isBannerVisible,
      closeAlert() {
        this.dialogPanelStore.setBannerDissmissedIndex(props.currentView);
        hidden.value = true;
      },
    };
  },
};
</script>
<style>
.custom_icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
#announcement_banner {
  background-color: #fff;
}
#announcement_banner > .v-alert__content {
  color: rgb(0 0 0 / 87%) !important;
  background-color: #fff !important;
}
</style>
