<template>
  <v-main id="main">
    <h1 class="d-sr-only">{{ $t("TheMain.heading") }}</h1>
    <slot />
  </v-main>
</template>

<script>
export default {
  name: "TheMain",
};
</script>
