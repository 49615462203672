import { useAuthStore } from "@/stores/auth";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { has } from "lodash/object";
import { useDialogPanelStore } from "@/stores/dialog-panel";
// import {
//  BannerData,
// } from "../../tests/mock-data/bannerdatamock";
export async function isUser(to, from, next) {
  try {
    const authStore = useAuthStore();
    const user = await authStore.currentAuthenticatedUser();
    if (user.guest) {
      return next({ name: "sign-in" });
    }

    return next();
  } catch (e) {
    return next({ name: "sign-in" });
  }
}

export const isGuest = async (to, from, next) => {
  try {
    const authStore = useAuthStore();
    const user = await authStore.currentAuthenticatedUser();
    if (user) {
      authStore.setIsGuestFlow();
      return next();
    }
  } catch (e) {
    return next({ name: "error", query: { code: "401001" } });
  }
};

export const commitAccessKey = async (to, from, next) => {
  const authStore = useAuthStore();
  const ak = to.params.ak;
  if (["sign-in", "dashboard", "payouts", "profile"].indexOf(from?.name) > -1) {
    authStore.user = null;
  }
  if (authStore.ak && authStore.ak.raw === ak) {
    return next();
  }

  try {
    const decoded = atob(ak);

    let tkns = decoded.split("#");
    const paymentId = tkns[0];

    tkns = tkns[1].split("+");
    const transactionId = tkns[0];
    const recipientId = tkns[1];
    const mId = tkns[2];

    authStore.ak = {
      raw: ak,
      decoded,
      paymentId,
      transactionId,
      recipientId,
      mId,
    };
  } catch (err) {
    return next({ name: "notfound" });
  }

  return next();
};

export const deselect = () => {
  const authStore = useAuthStore();
  if (authStore.isGuest) return;

  const dialogPanelStore = useDialogPanelStore();
  if (dialogPanelStore.visible) {
    dialogPanelStore.hide();
  }

  const payoutStore = usePayoutStore();
  payoutStore.securityCodeRequired = false;
  payoutStore.disableCardExpiry = false;
  if (payoutStore.selected) {
    payoutStore.selected = null;
  }

  const payoutMethodStore = usePayoutMethodStore();
  if (payoutMethodStore.selected) {
    payoutMethodStore.selected = null;
  }
};

export const updateTitle = (to, from, next) => {
  if (has(to, "meta.title")) {
    document.title = `${to.meta.title} - Digital Payouts`;

    return next();
  }

  document.title = "Digital Payouts";

  return next();
};

export const fetchMerchantConfigData = async (to, from, next) => {
  const authStore = useAuthStore();
  const payoutStore = usePayoutStore();
  if (authStore.ak !== null) {
    const r = await payoutStore.fetchMerchantConfig(authStore.ak["mId"]);
    payoutStore.portalConfigurations = r?.data?.configurations; //x portal Data
    return next();
  }
  return next();
};

export async function getNotificationData(to, from, next) {
  try {
    const authStore = useAuthStore();
    const dialogPanelStore = useDialogPanelStore();

    const data = await authStore.getNotificationDataDDP();
    authStore.notificationData = data; //x BannerData
    dialogPanelStore.setBannerConfig(authStore.notificationData.enabled);
    return next();
  } catch (e) {
    return next();
  }
}
