import { defineStore } from "pinia";
import ddp from "@/api/ddp-api";
import { useAuthStore } from "@/stores/auth";

const state = () => {
  return {
    persisted: [],
    transient: null,
    allowed: [],
    selected: null,
  };
};

const getters = {
  isSelectedACH(state) {
    if (!state.selected) return false;

    return state.selected.source === "ACH";
  },

  isRTPSected(state) {
    if (!state.selected) return false;
    return state.selected.source === "RTP";
  },

  isSelectedDebit(state) {
    if (!state.selected) return false;
    return state.selected.source === "DEBIT";
  },
};

const actions = {
  // Setter method for RTP
  setPayoutSource(param) {
    this.selected.source = param;
  },
  async fetchPersisted() {
    const authStore = useAuthStore();
    const response = await ddp.getPayoutMethods(authStore.user.recipientId);

    this.persisted = response.data.accounts;

    return response.data;
  },

  async fetchAllowed(transactionId) {
    const authStore = useAuthStore();
    const r = await ddp.getAllowedPayoutMethods(
      authStore.user.recipientId,
      transactionId
    );

    this.allowed = r.data.methods;

    return r;
  },

  create(payoutMethod) {
    const authStore = useAuthStore();
    return ddp.createPayoutMethod(authStore.user.recipientId, payoutMethod);
  },

  delete(accountId) {
    const authStore = useAuthStore();
    return ddp.deletePayoutMethod(authStore.user.recipientId, accountId);
  },

  update({ accountId, data }) {
    const authStore = useAuthStore();
    return ddp.updatePayoutMethod(authStore.user.recipientId, accountId, data);
  },

  async fetchFinancialInstitutionName(routingNumber) {
    const res = await ddp.getFinancialInstitutionDetails(routingNumber);
    return res.data;
  },

  async fetchRoutingNumber() {
    const r = await ddp.getRoutingNumber();
    return r.data;
  },

  enrollMoneyNetwork(form) {
    const authStore = useAuthStore();
    return ddp.enrollMoneyNetwork(authStore.user.recipientId, form);
  },

  createMoneyNetworkSession(payload) {
    const authStore = useAuthStore();
    return ddp.createMoneyNetworkSession(authStore.user.recipientId, payload);
  },
};

export const usePayoutMethodStore = defineStore("payoutMethod", {
  state,
  getters,
  actions,
});
