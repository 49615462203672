import { i18n } from "@/plugins/i18n";

export const paymentStatuses = {
  DI: {
    text: i18n.global.t("paymentStatus.completed.label"),
    color: "success",
    message: {
      title: i18n.global.t("paymentStatus.completed.message.title"),
      subtext: i18n.global.t("paymentStatus.completed.message.subtext"),
    },
  },
  AR: {
    text: i18n.global.t("paymentStatus.reversed.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.reversed.message.title"),
      subtext: i18n.global.t("paymentStatus.reversed.message.subtext"),
    },
  },
  DA: { text: i18n.global.t("paymentStatus.accepted"), color: "success" },
  IP: {
    text: i18n.global.t("paymentStatus.claimed.label"),
    color: "success",
    message: {
      title: i18n.global.t("paymentStatus.claimed.message.title"),
      subtext: i18n.global.t("paymentStatus.claimed.message.subtext"),
    },
  },
  IR: {
    text: i18n.global.t("paymentStatus.inReview.label"),
    color: "success",
    message: {
      title: i18n.global.t("paymentStatus.inReview.message.title"),
      subtext: i18n.global.t("paymentStatus.inReview.message.subtext"),
    },
  },
  PR: {
    text: i18n.global.t("paymentStatus.pendingReview.label"),
    color: "success",
    message: {
      title: i18n.global.t("paymentStatus.pendingReview.message.title"),
      subtext: i18n.global.t("paymentStatus.pendingReview.message.subtext"),
    },
  },
  DR: {
    text: i18n.global.t("paymentStatus.rejected.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.rejected.message.title"),
      subtext: i18n.global.t("paymentStatus.rejected.message.subtext"),
    },
  },
  PE: {
    text: i18n.global.t("paymentStatus.expired.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.expired.message.title"),
      subtext: i18n.global.t("paymentStatus.expired.message.subtext"),
    },
  },
  AF: {
    text: i18n.global.t("paymentStatus.canceled.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.canceled.message.title"),
      subtext: i18n.global.t("paymentStatus.canceled.message.subtext"),
    },
  },
  RE: {
    text: i18n.global.t("paymentStatus.canceled.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.canceled.message.title"),
      subtext: i18n.global.t("paymentStatus.canceled.message.subtext"),
    },
  },
  DH: {
    text: i18n.global.t("paymentStatus.canceled.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.canceled.message.title"),
      subtext: i18n.global.t("paymentStatus.canceled.message.subtext"),
    },
  },
  PC: {
    text: i18n.global.t("paymentStatus.canceled.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.canceled.message.title"),
      subtext: i18n.global.t("paymentStatus.canceled.message.subtext"),
    },
  },
  DNF: {
    text: i18n.global.t("paymentStatus.canceled.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.canceled.message.title"),
      subtext: i18n.global.t("paymentStatus.canceled.message.subtext"),
    },
  },
  DC: {
    text: i18n.global.t("paymentStatus.failed.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.failed.message.title"),
      subtext: i18n.global.t("paymentStatus.failed.message.subtext"),
    },
  },
  RS: {
    text: i18n.global.t("paymentStatus.reversalSuccess.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.reversalSuccess.message.title"),
      subtext: i18n.global.t("paymentStatus.reversalSuccess.message.subtext"),
    },
  },
  RP: {
    text: i18n.global.t("paymentStatus.reversalPending.label"),
    color: "warning",
    message: {
      title: i18n.global.t("paymentStatus.reversalPending.message.title"),
      subtext: i18n.global.t("paymentStatus.reversalPending.message.subtext"),
    },
  },
  RF: {
    text: i18n.global.t("paymentStatus.reversalFailed.label"),
    color: "error",
    message: {
      title: i18n.global.t("paymentStatus.reversalFailed.message.title"),
      subtext: i18n.global.t("paymentStatus.reversalFailed.message.subtext"),
    },
  },
  DP: {
    text: i18n.global.t("paymentStatus.disbursementPending"),
    color: "warning",
  },
  DD: { text: i18n.global.t("paymentStatus.multi.declined"), color: "error" },
  DS: { text: i18n.global.t("paymentStatus.multi.claimed"), color: "success" },
  PH: {
    text: i18n.global.t("paymentStatus.completed.label"),
    color: "success",
    message: {
      title: i18n.global.t("paymentStatus.paymentHold.message.title"),
      subtext: "",
    },
  },
};
export const multiPaymentStatuses = {
  DP: { text: i18n.global.t("paymentStatus.multi.pending"), color: "warning" },
  DA: { text: i18n.global.t("paymentStatus.multi.approved"), color: "success" },
};
