import { createApp, h } from "vue";
import App from "./App.vue";

import vuetify from "./plugins/vuetify";
import plugins from "@/plugins";
import JsonExcel from "vue-json-excel3";
import PortalVue from "portal-vue";
import AuthLayout from "./layouts/AuthLayout.vue";
import AnnouncementBannerWrapper from "@/components/AnnouncementBannerWrapper.vue";
import PayoutDetailsActions from "@/components/payouts/PayoutDetailsActions.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { VueMaskDirective } from "v-mask";
import { vMaska } from "maska";
//import { loadFonts } from './plugins/webfontloader'

//loadFonts()
import router from "@/router";
import VueAnnouncer from "@vue-a11y/announcer";
import VueSkipTo from "@vue-a11y/skip-to";
import VueFocusLoop from "@vue-a11y/focus-loop";

// import "@/filters";
// import "@/directives";
import "@/scss/index.scss";
const { pinia, i18n } = plugins;
const app = createApp({
  render: () => h(App),
});
app.use(vuetify);
app.use(pinia);
app.use(VueSkipTo);
app.use(VueFocusLoop);
app.use(i18n);
app.use(PortalVue);
app.use(router);
app.directive("mask", VueMaskDirective);
app.directive("maska", vMaska);
app.use(VueAnnouncer, { router });
app.component("DownloadExcel", JsonExcel);
app.component("AuthLayout", AuthLayout);
app.component("SvgIcon", SvgIcon);
app.component("AnnouncementBannerWrapper", AnnouncementBannerWrapper);
app.component("PayoutDetailsActions", PayoutDetailsActions);
app.mount("#app");
