<template>
  <v-sheet
    class="d-flex align-center justify-space-between panel px-3 py-5"
    :class="{ 'opacity-50': disabled }"
  >
    <div class="card-split ml-1">
      <v-tooltip
        :disabled="!disabled"
        top
        max-width="300"
        data-test="disabled-payout-method-tooltip"
        :open-on-click="true"
      >
        <template #activator="{ on, attrs }">
          <button
            :id="'icon_' + method.name"
            v-bind="attrs"
            plain
            color="primary"
            :ripple="false"
            class="btn-opaque pl-0 pr-0 w-full"
            :aria-disabled="disabled"
            data-test="payout-method-btn"
            @click="!disabled && setPayoutTypesrc(method)"
            v-on="on"
          >
            <div class="flex flex-column align-center justify-start w-full">
              <new-payout-method-card-icon
                :src="method.graphic.src"
                :type="method.graphic.type"
                :method-source="method.source"
                class="d-flex align-center justify-start"
              />

              <new-payout-method-card-name
                :name="method.name"
                class="d-flex align-center justify-start"
              />
            </div>
          </button>
        </template>

        <span data-test="disabled-payout-method-tooltip" aria-live="polite">
          {{ $t("NewPayoutMethodCardMobile.nonSavedAccountMessage") }}
        </span>
      </v-tooltip>
    </div>

    <div class="mr-3">
      <new-payout-method-card-timeframe :time-frame="method.timeFrame" />

      <read-more-tooltip>{{ method.tooltip }}</read-more-tooltip>
    </div>
  </v-sheet>
</template>

<script>
import NewPayoutMethodCardIcon from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardIcon";
import NewPayoutMethodCardName from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardName";
import ReadMoreTooltip from "@/components/payout-methods/ReadMoreTooltip";
import NewPayoutMethodCardTimeframe from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardTimeframe";
import { useDynamicContent } from "@/composables/dynamic-content";
import { usePayoutStore } from "@/stores/payout";

export default {
  name: "NewPayoutMethodCardMobile",

  components: {
    NewPayoutMethodCardIcon,
    NewPayoutMethodCardName,
    ReadMoreTooltip,
    NewPayoutMethodCardTimeframe,
  },

  props: {
    method: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },

  setup() {
    const { setContent } = useDynamicContent();
    const payoutStore = usePayoutStore();
    return { setContent, payoutStore };
  },

  methods: {
    setPayoutTypesrc(method) {
      this.payoutStore.setPayoutTypeSelectedsource(method.source);
      this.setContent(method.component);
    },
  },
};
</script>

<style scoped>
.opacity-50 {
  opacity: 0.5;
}

.card-split {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    padding: 0 0 !important;
  }
}
</style>
