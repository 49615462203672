import { defineStore } from "pinia";
import { v4 } from "uuid";

const state = () => {
  return {
    error: {
      show: false,
      message: "",
    },
    traceId: null,
  };
};

const getters = {
  getTraceId() {
    return this.traceId;
  },
};
const actions = {
  showError(message) {
    this.error = { show: true, message };
  },

  hideError() {
    this.error = { show: false, message: "" };
  },

  setTraceId() {
    this.traceId = v4();
  },
  resetTraceId() {
    this.traceId = null;
  },
};

export const useAppStore = defineStore("app", {
  state,
  actions,
  getters,
});
