<script>
import { computed } from "vue";
import { mdiCreditCardOutline } from "@mdi/js"; // mdiBank
//import MoneyNetworkLogo from "@/assets/img/payout-methods/moneynetwork.svg?inline";
import PaypalLogo from "@/assets/img/payout-methods/Paypal-newLogo.svg?inline";
import VenmoLogo from "@/assets/img/payout-methods/Venmo-new-logo.svg?inline";
// import CryptoNormal from "@/assets/img/payout-methods/cryptonormal.svg?inline";
import CoinBaseLogo from "@/assets/img/payout-methods/coinbase.svg?inline";
import visaLogo from "@/assets/img/visa.svg?inline";
import masterCardLogo from "@/assets/img/mastercard.svg?inline";
import achLogo from "@/assets/img/payout-methods/ach.svg?inline";
import MoneyNetworkLogo from "@/assets/img/payout-methods/moneynetwork-newLogo.svg?inline";

const icons = {
  ACH: { mdi: false, svg: achLogo },
  DEBIT: { mdi: true, svg: mdiCreditCardOutline },
  COINBASE: { mdi: false, svg: CoinBaseLogo },
  PREPAID: { mdi: false, svg: MoneyNetworkLogo },
  PAYPAL: { mdi: false, svg: PaypalLogo },
  VENMO: { mdi: false, svg: VenmoLogo },
  MASTERCARD: { mdi: false, svg: masterCardLogo },
  VISA: { mdi: false, svg: visaLogo },
  RTP: { mdi: false, svg: achLogo },
};

export default {
  props: {
    payoutMethod: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const icon = computed(() => {
      if (props.payoutMethod.source === "DEBIT") {
        switch (props.payoutMethod?.card?.cardBrand?.toUpperCase()) {
          case "MASTERCARD":
          case "VISA":
            return icons[props.payoutMethod.card.cardBrand.toUpperCase()];
          default:
            return icons[props.payoutMethod.source];
        }
      } else {
        return icons[props.payoutMethod.source];
      }
    });

    return { icon };
  },

  computed: {
    payoutIconclass() {
      if (this.payoutMethod.source === "DEBIT") {
        if (
          this.payoutMethod?.card?.cardBrand?.toUpperCase() === "MASTERCARD"
        ) {
          return "desktopMaster";
        } else if (
          this.payoutMethod?.card?.cardBrand?.toUpperCase() === "VISA"
        ) {
          return "desktopVisa";
        } else {
          return "test";
        }
      } else {
        return "test";
      }
    },
  },
};
</script>

<template>
  <VIcon v-if="icon.mdi" size="25" color="secondary">{{ icon.svg }}</VIcon>
  <component
    :is="icon.svg"
    v-else
    class="payout-method-icon"
    :class="payoutIconclass"
  />
</template>
<!--  " -->
<style scoped>
.payout-method-icon {
  fill: var(--v-secondary-base);
  width: 64px !important;
}
.desktopVisa {
  margin-left: -3px;
}

.desktopMaster {
  margin-left: -7.5px;
}
</style>
