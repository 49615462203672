<template>
  <div class="d-flex flex-column">
    <v-col v-if="getSelectedPayoutSrc == 'US_DEBIT'" cols="2">
      <USDbitLogo class="usdebit-network-logo" />
    </v-col>
    <div class="px-4 primary--text" data-test="info-section">
      <div v-if="prepaidType === 'PREPAID' || prepaidType === 'MONEYNETWORK'">
        {{
          accountType === "CARD"
            ? `${formVerbiage.cardInfo}`
            : `${formVerbiage.accountInfo}`
        }}
      </div>
      <div v-else>
        {{ formVerbiage.cardInfo }}
      </div>
    </div>

    <v-row class="px-3 px-sm-4 pt-6">
      <v-col
        v-if="prepaidType === 'PREPAID' || prepaidType === 'MONEYNETWORK'"
        cols="12"
        sm="6"
      >
        <v-select
          id="MNaccounttype_select"
          v-model="accountType"
          :items="accountTypeItems"
          item-title="text"
          item-value="value"
          outlined
          :disabled="isEditing"
          data-test="account-type"
          class="dropleftalign"
          @update:model-value="resetForm()"
        ></v-select>
      </v-col>

      <v-col v-if="nicknameEnabled" cols="12" sm="6">
        <nickname-text-field v-model="nickname" />
      </v-col>
    </v-row>

    <v-row class="px-3 px-sm-4">
      <v-col cols="12" sm="6">
        <name-text-field
          v-model="firstName"
          :label="formVerbiage.firstName"
          maxlength="75"
          disabled
          data-test="first-name"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <name-text-field
          v-model="lastName"
          :label="formVerbiage.lastName"
          disabled
          data-test="last-name"
        />
      </v-col>
    </v-row>

    <v-form ref="form" class="pt-4">
      <v-row v-if="accountType === 'CARD'" class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <secure-text-field
            v-model.trim="cardNumber"
            :label="formVerbiage.cardNumber"
            :rules="isEditing ? [] : [required(), betweenNumeric(16, 19)]"
            :disabled="isEditing"
            maxlength="30"
            data-test="card-number"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <secure-text-field
            v-model.trim="confirmCardNumber"
            :label="formVerbiage.confirmCardNumber"
            :rules="
              isEditing
                ? []
                : [required(), betweenNumeric(16, 19), cardNumbersMatch]
            "
            :disabled="isEditing"
            data-test="confirm-card-number"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <v-row v-else class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <secure-text-field
            v-model.trim="accountNumber"
            :label="formVerbiage.accountNumber"
            :rules="isEditing ? [] : [required(), betweenNumeric(13, 19)]"
            :disabled="isEditing"
            maxlength="30"
            data-test="account-number"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <secure-text-field
            v-model.trim="confirmAccountNumber"
            :label="formVerbiage.confirmAccountNumber"
            :rules="
              isEditing
                ? []
                : [required(), betweenNumeric(13, 19), accountNumbersMatch]
            "
            :disabled="isEditing"
            data-test="confirm-account-number"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <div class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <terms-and-conditions-checkbox
          v-model="termsAndConditions"
          data-test="terms-and-conditions"
          class="payoutTermsCondition"
        />
      </div>

      <div v-if="transient" class="d-flex pt-5 pt-sm-0 px-3 px-sm-4">
        <v-checkbox
          v-if="!isGuest"
          v-model="savePayoutAccount"
          :error-messages="errorSavedPayoutMethod"
          class="customized_info"
          :label="$t('PayoutsAvailable.savePayoutAccount')"
          data-test="save-payout-method"
          aria-required="true"
          :disabled="savedPayoutMethods.length > 4"
        >
        </v-checkbox>
      </div>

      <v-row v-if="savePayoutAccount" class="px-3 px-sm-4">
        <v-col cols="12" sm="6">
          <nickname-text-field v-model="nickname" />
        </v-col>
      </v-row>

      <div v-if="!transient" class="d-flex px-3 px-sm-4">
        <v-checkbox
          v-model="isDefault"
          :disabled="isEditing && selectedPayoutMethod.prepaid.default"
          :label="formVerbiage.defaultPayoutMethod"
          data-test="default-method"
        />
      </div>

      <div class="d-flex justify-center pt-10">
        <v-btn
          depressed
          tile
          :disabled="disburseLoading || disableForm"
          :loading="disburseLoading || persistLoading"
          color="primary"
          class="mx-2"
          :class="{
            custom_min_width_100: isEditing || transient,
            custom_min_width_150: !(isEditing || transient),
          }"
          data-test="add-account"
          @click="submit"
        >
          {{
            isEditing || transient
              ? `${formVerbiage.submit}`
              : `${formVerbiage.addAccount}`
          }}
        </v-btn>
        <v-btn
          variant="outlined"
          tile
          class="mx-2 custom_min_width_100"
          data-test="cancel"
          @click="showConfirmCancel = true"
        >
          {{ formVerbiage.cancel }}
        </v-btn>
      </div>

      <div v-if="disableForm" class="d-flex px-3 pt-6 px-sm-4">
        <v-alert type="error" tile>
          <span data-test="disburse-error-message">
            {{ disburseError }}
          </span>
        </v-alert>
      </div>
    </v-form>

    <div
      v-if="
        !isEditing &&
        (prepaidType === 'PREPAID' || prepaidType === 'MONEYNETWORK')
      "
    >
      <v-divider class="mt-10" />

      <div class="text-center pt-10">
        <div>{{ formVerbiage.noCard }}</div>
        <v-btn
          depressed
          tile
          color="primary"
          class="mt-3"
          :disabled="loadingSignup"
          :loading="loadingSignup"
          data-test="sign-up-btn"
          @click="createSession"
          >{{ formVerbiage.signup }}</v-btn
        >
      </div>
    </div>

    <payout-methods-confirm-cancel-dialog
      v-model="showConfirmCancel"
      :editing="isEditing"
    />
  </div>
</template>

<script>
import NameTextField from "@/components/forms/NameTextField";
import SecureTextField from "@/components/forms/SecureTextField";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import { required, betweenNumeric } from "@/utils/rules";
import { useAuthStore } from "@/stores/auth";
import { useTitle } from "@/composables/title";
import { usePersistPayoutMethod } from "@/composables/persist-payout-method";
import { computed } from "vue";
import isEqual from "lodash/isEqual";
import { mapState } from "pinia";
import { useTransientPayoutMethod } from "@/composables/transient-payout-method";
import { useDisburse } from "@/composables/disburse";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import NicknameTextField from "@/components/forms/NicknameTextField";
import { useDynamicContent } from "@/composables/dynamic-content";
import NewMoneyNetworkForm from "@/components/payout-methods/money-network/NewMoneyNetworkForm";
import { resolveErrorMsg } from "@/utils/error-handler";
import { useAppStore } from "@/stores/app";
import { setVerbiage } from "@/utils/payout";
import { ref } from "vue";
import USDbitLogo from "@/assets/img/usdebitcardlogo.svg?inline";
import { i18n } from "@/plugins/i18n";

export default {
  name: "MoneyNetworkForm",

  components: {
    NicknameTextField,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
    SecureTextField,
    NameTextField,
    USDbitLogo,
  },

  setup() {
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const prepaidType = ref(payoutStore.getPayoutTypeSelectedSource);
    const formVerbiage = setVerbiage(prepaidType.value);
    const payoutMethodStore = usePayoutMethodStore();
    const appStore = useAppStore();
    const { setContent } = useDynamicContent();

    const title = authStore.isGuest
      ? formVerbiage.guestTitle
      : formVerbiage.registerTitle;
    useTitle(title);

    const { store } = useTransientPayoutMethod();
    const {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
    } = useDisburse();
    const { persistLoading, persist, isEditing } =
      usePersistPayoutMethod("MoneyNetworkForm");
    const onSubmit = computed(() => (payoutStore.selected ? store : persist));

    const getSelectedPayoutSrc = computed(
      () => payoutStore.getPayoutTypeSelectedSource
    );

    return {
      appStore,
      payoutMethodStore,
      disburse,
      disburseLoading,
      disburseError,
      transferDisabled,
      isEditing,
      persistLoading,
      onSubmit,
      required,
      betweenNumeric,
      setContent,
      formVerbiage,
      prepaidType,
      getSelectedPayoutSrc,
      payoutStore,
    };
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      accountNumber: null,
      confirmAccountNumber: null,
      cardNumber: null,
      confirmCardNumber: null,
      termsAndConditions: false,
      isDefault: false,
      accountType: "CARD",
      nickname: null,
      accountTypeItems: [
        {
          text: "Card Number",
          value: "CARD",
        },
        {
          text: "Account Number",
          value: "ACCOUNT",
        },
      ],
      showConfirmCancel: false,
      NewMoneyNetworkForm,
      loadingSignup: false,
      savePayoutAccount: false,
    };
  },

  computed: {
    ...mapState(useAuthStore, ["user", "isUser"]),
    ...mapState(useAuthStore, ["session", "isGuest"]),
    ...mapState(usePayoutStore, { selectedPayout: "selected" }),
    ...mapState(usePayoutMethodStore, { selectedPayoutMethod: "selected" }),
    ...mapState(usePayoutStore, ["payoutTypeSelectedSource"]),
    ...mapState(usePayoutMethodStore, { savedPayoutMethods: "persisted" }),

    transient() {
      return this.isGuest || !!this.selectedPayout;
    },

    errorSavedPayoutMethod() {
      const messageArray = [];
      this.savedPayoutMethods.length > 4
        ? messageArray.push(
            i18n.global.t("PayoutsAvailable.savePayoutAccountError")
          )
        : [];
      return messageArray;
    },

    disableForm() {
      return this.transferDisabled;
    },

    apiSchema() {
      return {
        recipientProfileInfo: {
          recipientId: this.user.recipientId,
        },
        source: "PREPAID",
        label: this.nickname ? this.nickname : "",
        prepaid: {
          brand: this.prepaidType,
          accountNumber: this.accountNumber,
          cardNumber: this.cardNumber,
          default: this.isDefault,
        },
      };
    },

    accountNumbersMatch() {
      return (
        isEqual(this.accountNumber, this.confirmAccountNumber) ||
        i18n.global.t("MoneyNetworkForm.confirmAccountNumbers")
      );
    },

    cardNumbersMatch() {
      return (
        isEqual(this.cardNumber, this.confirmCardNumber) ||
        i18n.global.t("MoneyNetworkForm.confirmCardNumbers")
      );
    },

    nicknameEnabled() {
      return (
        process.env.VUE_APP_PAYOUT_METHOD_NICKNAMES_ENABLED === "true" &&
        !this.transient
      );
    },
  },

  created() {
    this.prePopulateForm();
  },

  mounted() {
    const nodeList = document.querySelector("#MNaccounttype_select");
    if (nodeList) {
      const selectLblParent = nodeList.parentElement.firstElementChild;
      if (selectLblParent) {
        const lblelement = selectLblParent.firstElementChild;
        lblelement.style.marginLeft = "-16px";
      }
    }
  },

  methods: {
    // Reset
    resetForm() {
      this.$refs.form.reset();
      setTimeout(() => {
        const nodeList = document.querySelector("#MNaccounttype_select");
        const selectLblParent = nodeList.parentElement.firstElementChild;
        const lblelement = selectLblParent.firstElementChild;
        lblelement.style.marginLeft = "-16px";
      });
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return;
      await this.onSubmit(this.apiSchema);

      if (this.transient) {
        this.payoutStore.setSavePayoutAccount(
          this.savePayoutAccount ? "true" : "false"
        );
        this.disburse();
      }
    },

    async createSession() {
      this.loadingSignup = true;
      await this.payoutMethodStore
        .createMoneyNetworkSession({
          brand: this.prepaidType,
          transactionId: this.payoutStore?.selected?.transactionId,
        })
        .then((res) => {
          this.setContent({
            component: NewMoneyNetworkForm,
            attrs: { enrollmentData: res.data },
          });
        })
        .catch((err) => {
          const msg = resolveErrorMsg(err, "");
          this.appStore.showError(msg);
        })
        .finally(() => {
          this.loadingSignup = false;
        });
    },

    prePopulateForm() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;

      if (this.isEditing) {
        this.isDefault = this.selectedPayoutMethod.prepaid.default;
        this.nickname = this.selectedPayoutMethod.label ?? "";

        if (this.selectedPayoutMethod.prepaid.cardNumber) {
          this.accountType = "CARD";
          this.cardNumber = "********";
          this.confirmCardNumber = "********";
        } else {
          this.accountType = "ACCOUNT";
          this.accountNumber = "********";
          this.confirmAccountNumber = "********";
        }
      }
    },
  },
};
</script>
<style scoped>
.usdebit-network-logo {
  height: 6.5rem;
  top: 1rem;
  position: relative;
}
</style>
