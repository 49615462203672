<template>
  <app-text-field
    v-model.trim="modelValue"
    :rules="isAniVisa ? [] : rules"
    :label="label"
    v-bind="$attrs"
  />
</template>

<script>
import AppTextField from "@/components/forms/AppTextField";
import { required } from "@/utils/rules";
import { useVModel } from "@vueuse/core";
import { i18n } from "@/plugins/i18n";
import { usePayoutStore } from "@/stores/payout";
import { computed, ref, onMounted } from "vue";

export default {
  name: "NameTextField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    value: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    requiredMsg: { type: String, default: null },
  },

  setup(props, { emit }) {
    const modelValue = useVModel(props, "value", emit, { eventName: "input" });
    const payoutStore = usePayoutStore();
    const payoutSource = payoutStore.getPayoutTypeSelectedSource;
    const isAniVisa = ref(false);
    const isRecipientVisaEnable = computed(() => {
      return payoutStore?.portalConfigurations
        ?.ENABLE_VISA_RECIPIENT_NAME_READONLY === "TRUE"
        ? true
        : false;
    });
    onMounted(() => {
      if (payoutSource.toLowerCase() === "visa" && isRecipientVisaEnable.value)
        isAniVisa.value = true;
    });

    return {
      modelValue,
      required,
      isAniVisa,
    };
  },

  data() {
    return {
      rules: [this.required(this.requiredMsg), this.validChars],
    };
  },

  methods: {
    validChars(v) {
      return (
        /^([a-z.'\s-]+)$/i.test(v) ||
        i18n.global.t("NameTextField.invalidNameChars", {
          label: this.label,
        })
      );
    },
  },
};
</script>
