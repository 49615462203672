<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.colors.background }">
    <the-skip-links />

    <the-nav-bar />

    <the-main>
      <announcement-banner-wrapper></announcement-banner-wrapper>
      <router-view />
      <the-footer />
    </the-main>

    <the-mobile-bottom-nav-bar v-if="authStore.isUser" />

    <the-error-alert />
  </v-app>
</template>

<script>
import TheNavBar from "@/components/TheNavBar";
import TheErrorAlert from "@/components/TheErrorAlert";
import TheFooter from "@/components/TheFooter";
import TheMobileBottomNavBar from "@/components/TheMobileBottomNavBar";
import TheSkipLinks from "@/components/TheSkipLinks";
import TheMain from "@/components/TheMain";
import AnnouncementBannerWrapper from "@/components/AnnouncementBannerWrapper.vue";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "DefaultLayout",

  components: {
    TheMain,
    AnnouncementBannerWrapper,
    TheSkipLinks,
    TheMobileBottomNavBar,
    TheFooter,
    TheErrorAlert,
    TheNavBar,
  },

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
};
</script>
