<template>
  <div data-test="new-payout-card">
    <new-payout-method-card-desktop
      v-if="smAndUp"
      :disabled="disabled"
      :method="method"
    />

    <new-payout-method-card-mobile
      v-else
      :disabled="disabled"
      :method="method"
    />
  </div>
</template>

<script>
import NewPayoutMethodCardDesktop from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardDesktop";
import NewPayoutMethodCardMobile from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardMobile";
import { useDisplay } from "vuetify";

export default {
  name: "NewPayoutMethodCard",

  components: {
    NewPayoutMethodCardMobile,
    NewPayoutMethodCardDesktop,
  },

  props: {
    method: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },

  setup() {
    const { smAndUp } = useDisplay();

    return { smAndUp };
  },
};
</script>
