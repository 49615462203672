import { computed, ref } from "vue";
import { useAppStore } from "@/stores/app";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { payoutMethodDetail } from "@/utils/payout-methods";
import { encryptPayoutMethod } from "@/utils/encryption";
import { resolveErrorMsg } from "@/utils/error-handler";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { usePayoutStore } from "@/stores/payout";

export function usePersistPayoutMethod(errorContext) {
  const payoutMethodStore = usePayoutMethodStore();
  const payoutStore = usePayoutStore();

  const persistLoading = ref(false);
  const isEditing = computed(
    () => payoutMethodStore.selected != null && payoutStore.selected == null
  );

  const persist = async (apiSchema) => {
    persistLoading.value = true;

    const accounts = isEditing.value
      ? apiSchema
      : encryptPayoutMethod(apiSchema);

    const wrapped = { accounts };

    try {
      const upsert = isEditing.value ? updatePayoutMethod : createPayoutMethod;
      await upsert(wrapped, payoutMethodStore);
      await done(payoutMethodStore);
    } catch (err) {
      const appStore = useAppStore();
      const msg = resolveErrorMsg(err, errorContext);
      appStore.showError(msg);
    } finally {
      persistLoading.value = false;
    }
  };

  return {
    isEditing,
    persistLoading,
    persist,
  };
}

async function createPayoutMethod(payload, store) {
  return store.create(payload);
}

async function updatePayoutMethod(data, store) {
  const detail = payoutMethodDetail(store.selected);
  const newData = data;

  if (store.selected.source.toLowerCase() === "debit") {
    delete newData.accounts.card.nameOnCard;
    delete newData.accounts.card.cardNumber;
    delete newData.accounts.card.securityCode;
    delete newData.accounts.card.firstName;
    delete newData.accounts.card.lastName;
  }

  const payload = {
    accountId: detail.token.tokenId,
    data,
  };

  return store.update(payload);
}

async function done(payoutMethodStore) {
  const dialogPanelStore = useDialogPanelStore();

  await payoutMethodStore.fetchPersisted();
  payoutMethodStore.selected = null;

  dialogPanelStore.hide();
}
