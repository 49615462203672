<template>
  <div class="d-flex flex-column justify-center align-center h-full">
    <svg-icon
      class="pt-15 warning"
      size="60"
      data-test="check-mark-warning"
      type="mdi"
      :path="mdiAlert"
    >
    </svg-icon>

    <div class="text-h3 pt-15" data-test="payout-progress-message">
      {{ $t("TimeoutError.payoutProgress") }}
    </div>

    <div class="mt-10 pt-10" data-test="message-section">
      {{ msgContent }}
    </div>

    <div class="mt-10 pt-10">
      <v-btn
        class="mx-2"
        tile
        variant="outlined"
        elevation="0"
        color="primary"
        min-width="125"
        data-test="done-button"
        @click="done"
      >
        {{ $t("TimeoutError.done") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from "@mdi/js";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDialogPanelStore } from "@/stores/dialog-panel";

export default {
  name: "TimeoutError",

  props: {
    msgContent: { type: String, default: "" },
  },

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();

    useTitle(i18n.global.t("TimeoutError.title"));

    return {
      dialogPanelStore,
      payoutStore,
      payoutMethodStore,
    };
  },

  data() {
    return {
      mdiAlert,
    };
  },

  methods: {
    done() {
      this.dialogPanelStore.hide();
      this.payoutStore.selected = null;
      this.payoutMethodStore.selected = null;
    },
  },
};
</script>
