import client from "@/api/clients/auth-client";

export default {
  signIn(credentials) {
    return client.post("/authenticate", credentials);
  },

  validateOTP(otp, sessionId) {
    return client.post(`/otp/${sessionId}/validate`, otp);
  },

  createAccessToken(ak) {
    return client.post("/accessTokens", { customerAccessKey: ak });
  },

  forgotPassword(emailAddress) {
    return client.post(`/forgotPassword`, { emailAddress, optStatus: "IN" });
  },

  resetPassword(data, sessionId) {
    return client.post(`/forgotPassword/${sessionId}`, data);
  },
};
