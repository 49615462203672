<template>
  <v-hover :disabled="disabled">
    <template #default="{ isHovering, props }">
      <v-sheet
        :elevation="isHovering ? 8 : 0"
        min-width="100px"
        class="d-flex flex-column align-center justify-space-between h-3 panel pt-4 pb-2 px-1"
        :class="{ 'opacity-50': disabled }"
        v-bind="props"
      >
        <v-tooltip
          :disabled="!disabled"
          location="top"
          max-width="300"
          :open-on-click="true"
        >
          <template #activator="{ props }">
            <button
              class="btn font-weight-bold"
              v-bind="props"
              data-test="payout-method-btn"
              :aria-disabled="disabled"
              @click="!disabled && setPayoutTypesrc(method)"
            >
              <new-payout-method-card-icon
                :src="method.graphic.src"
                :type="method.graphic.type"
                :method-source="method.source"
                class="mb-2"
              />

              <span
                class="font-weight-regular"
                data-test="new-payout-type-name"
                >{{ method.name }}</span
              >
            </button>
          </template>

          <span data-test="disabled-payout-method-tooltip" aria-live="polite">
            {{ $t("NewPayoutMethodCardDesktop.nonSavedAccountMessage") }}
          </span>
        </v-tooltip>

        <new-payout-method-card-timeframe :time-frame="method.timeFrame" />

        <read-more-tooltip :arl-lbl="method.name">{{
          method.tooltip
        }}</read-more-tooltip>
      </v-sheet>
    </template>
  </v-hover>
</template>
<script>
import NewPayoutMethodCardIcon from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardIcon";
import ReadMoreTooltip from "@/components/payout-methods/ReadMoreTooltip";
import NewPayoutMethodCardTimeframe from "@/components/payout-methods/new-payout-method-card/NewPayoutMethodCardTimeframe";
import { useDynamicContent } from "@/composables/dynamic-content";
import { usePayoutStore } from "@/stores/payout";

export default {
  name: "NewPayoutMethodCardDesktop",

  components: {
    NewPayoutMethodCardIcon,
    ReadMoreTooltip,
    NewPayoutMethodCardTimeframe,
  },

  props: {
    method: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },

  setup() {
    const { setContent } = useDynamicContent();
    const payoutStore = usePayoutStore();
    return { setContent, payoutStore };
  },
  methods: {
    setPayoutTypesrc(method) {
      this.payoutStore.setPayoutTypeSelectedsource(method.source);
      this.setContent(method.component);
    },
  },
};
</script>
<style scoped>
.h-3 {
  height: 11rem; /*Changed for tile height*/
}

.btn {
  color: var(--v-primary-base);
}

.btn:hover {
  color: var(--v-info-darken3);
}

.btn:disabled {
  color: var(--v-secondary-base);
}

.opacity-50 {
  opacity: 0.5;
}
</style>
