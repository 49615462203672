import staticApi from "@/api/static-api";
import { defineStore } from "pinia";

const state = () => {
  return {
    color: {},
    url: {},
    faqs: [],
  };
};

const actions = {
  async fetchMerchantLogoColor(merchant) {
    const res = await staticApi.getMerchantLogoColorDetails(merchant);

    this.color[merchant] = res.data.color;

    return res.data;
  },

  async fetchMerchantLogoImage(merchant) {
    const res = await staticApi.getMerchantLogoImageDetails(merchant);

    this.url[merchant] = res.request.responseURL;

    return res;
  },

  async fetchFaqsData(bankcode) {
    const res = await staticApi.getFaqsData(bankcode);
    this.faqs = res.data.faqs;

    return res;
  },
};

export const useStaticStore = defineStore("static", {
  state,
  actions,
});
