<template>
  <nav
    v-if="xs"
    id="mobile-bottom-nav"
    class="d-flex align-center justify-space-around px-12 mobile-bottom-nav-border white w-full"
    :aria-label="$t('TheMobileBottomNavBar.menu')"
  >
    <v-btn
      icon
      active-class="no-background"
      :to="{ name: 'dashboard' }"
      large
      :aria-label="$t('TheMobileBottomNavBar.dashboard')"
      :title="$t('TheMobileBottomNavBar.dashboard')"
      @click="backToTop"
    >
      <v-icon
        :color="$route.name === 'dashboard' ? 'accent' : 'primary'"
        data-test="mobile-nav-bar-home"
      >
        mdi-home-outline
      </v-icon>
    </v-btn>

    <v-btn
      icon
      active-class="no-background"
      :to="{ name: 'payouts' }"
      large
      :aria-label="$t('TheMobileBottomNavBar.payouts')"
      :title="$t('TheMobileBottomNavBar.payouts')"
    >
      <v-icon
        :color="$route.name === 'payouts' ? 'accent' : 'primary'"
        data-test="mobile-nav-bar-payouts"
      >
        mdi-cash-multiple
      </v-icon>
    </v-btn>

    <v-btn
      icon
      active-class="no-background"
      :to="{ name: 'faq' }"
      large
      :aria-label="$t('TheMobileBottomNavBar.faq')"
      :title="$t('TheMobileBottomNavBar.faq')"
    >
      <v-icon
        :color="$route.name === 'faq' ? 'accent' : 'primary'"
        data-test="mobile-nav-bar-faq"
      >
        mdi-frequently-asked-questions
      </v-icon>
    </v-btn>

    <div class="px-4">
      <the-user-menu>
        <template #default="{ props }">
          <v-btn
            v-bind="props"
            data-test="mobile-nav-bar-profile-icon"
            icon
            :aria-label="$t('TheMobileBottomNavBar.userMenuLabel')"
            :title="$t('TheMobileBottomNavBar.userMenuLabel')"
          >
            <v-icon :color="$route.name === 'profile' ? 'accent' : 'primary'">
              mdi-account-outline
            </v-icon>
          </v-btn>
        </template>
      </the-user-menu>
    </div>
  </nav>
</template>

<script>
import TheUserMenu from "./TheUserMenu.vue";
import {
  mdiAccountOutline,
  mdiHomeOutline,
  mdiCashMultiple,
  mdiFrequentlyAskedQuestions,
} from "@mdi/js";
import { useDisplay } from "vuetify";
export default {
  name: "TheMobileBottomNavBar",

  components: { TheUserMenu },

  setup() {
    const { xs } = useDisplay();
    return { xs };
  },

  data() {
    return {
      mdiAccountOutline,
      mdiHomeOutline,
      mdiCashMultiple,
      mdiFrequentlyAskedQuestions,
    };
  },

  methods: {
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
/*added important to override the primary white color*/
.mobile-bottom-nav-border {
  position: fixed;
  height: 82px;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--v-background-base) !important;
}
/*make the background color of button to none (by default button rendering with the shadow background)*/
.v-btn:before {
  background: none !important;
}
</style>
