<template>
  <v-app>
    <v-main class="m-height">
      <v-row no-gutters class="h-full">
        <v-col
          cols="12"
          md="7"
          :class="smAndDown ? ['text-h4', 'fixed-height'] : 'text-h3'"
        >
          <auth-cover />
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="h-full"
          :class="{
            'container-lines': mdAndUp,
          }"
        >
          <div class="d-flex flex-column h-full">
            <div v-if="mdAndUp" class="pl-4 pl-md-16 pt-4 pt-md-14">
              <carat-logo data-test="carat-logo" class="h-10" />
            </div>

            <div class="banner_container">
              <announcement-banner-wrapper></announcement-banner-wrapper>
            </div>

            <v-alert
              v-if="error.show"
              tile
              height="auto"
              width="100%"
              dark
              class="d-flex align-center px-4 px-sm-16"
              data-test="auth-error-alert"
              color="error"
            >
              <div class="d-flex align-center">
                <v-icon large> mdi-alert-circle-outline </v-icon>
                <div class="ml-4">
                  <div class="font-weight-bold">{{ error.title }}</div>
                  <div>{{ error.message }}</div>
                </div>
              </div>
            </v-alert>

            <v-alert
              v-if="warning.show"
              tile
              height="84"
              width="100%"
              dark
              class="d-flex align-center px-4 px-sm-16"
              data-test="auth-warning-alert"
              color="authWarning"
            >
              <div class="d-flex align-center">
                <v-icon large> mdi-alert-outline </v-icon>
                <div class="ml-4">
                  <div class="font-weight-bold">{{ warning.title }}</div>
                  <div>{{ warning.message }}</div>
                </div>
              </div>
            </v-alert>

            <div
              style="height: 84px"
              class="mb-4"
              :class="{ 'mt-16': mdAndUp }"
            />

            <div class="flex-grow-1" :class="xs ? 'mt-8' : 'mt-5'">
              <div class="px-4 px-md-16 h-full pb-2 d-flex flex-column">
                <router-view />
                <div class="d-flex justify-end align-center mt-12 pb-12">
                  <div>
                    <div class="mr-2">
                      <privacy-policy
                        class="text-decoration-underline fiserv-dark-blue--text"
                      />
                    </div>
                  </div>
                  <div class="divider"></div>
                  <router-link
                    :to="{ name: 'faq' }"
                    class="fiserv-blue--text"
                    :aria-label="$t('SignInForm.needHelp')"
                    data-test="need-help-link"
                  >
                    <div>
                      <div class="mr-2">
                        {{ $t("SignInForm.needHelp") }}
                      </div>
                    </div>
                  </router-link>
                </div>

                <!--                If the router is reintroduced to the AuthLayout, it appears to look the best inside of the router container. -->
                <!--                <the-footer />-->
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import AnnouncementBannerWrapper from "@/components/AnnouncementBannerWrapper";
import {
  mdiAlertCircleOutline,
  mdiArrowTopRight,
  mdiAlertOutline,
} from "@mdi/js";
import AuthCover from "@/components/auth/AuthCover";
import CaratLogo from "@/assets/img/carat_logo.svg?inline";
import { useAuthStore } from "@/stores/auth";
import privacyPolicy from "@/components/legal/privacyPolicy.vue";
import { useDisplay } from "vuetify";

// import TheFooter from "@/components/TheFooter";

export default {
  name: "AuthLayout",

  components: {
    privacyPolicy,
    AuthCover,
    CaratLogo,
    AnnouncementBannerWrapper,
  },

  setup() {
    const authStore = useAuthStore();
    const { smAndDown, mdAndUp, xs } = useDisplay();
    return { authStore, smAndDown, mdAndUp, xs };
  },

  data() {
    return {
      mdiArrowTopRight,
      mdiAlertCircleOutline,
      mdiAlertOutline,
    };
  },

  computed: {
    error() {
      return this.authStore.error;
    },
    warning() {
      return this.authStore.warning;
    },
  },

  created() {
    this.authStore.clearAuthError();
    this.authStore.clearAuthWarning();
  },
};
</script>

<style scoped>
.container-lines {
  background: no-repeat url("~@/assets/img/carat_lines.png");
}

.h-10 {
  height: 2.5rem;
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1400px) {
  .px-md-16 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }
}

@media screen and (min-device-width: 960px) and (max-device-width: 1024px) {
  .px-md-16 {
    padding-right: 34px !important;
    padding-left: 34px !important;
  }
}

.m-height {
  min-height: 100vh;
}

.split-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.divider {
  width: 1px;
  height: 20px;
  margin: 0 10px 0 3px;
  background: rgba(0, 0, 0, 0.2);
}
</style>
